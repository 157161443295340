import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
export default function Kannada() {
    return (
        <section className="blog-area pt182">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 tab mb-20">
                        <ul className="nav nav-tabs" role="tablist">
                            {/* <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">અવારનવાર પૂછાતા પ્રશ્નો </Link></li> */}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="all-single-blog rpt">
                            <div className="carrd1 requestbox">
                                <div className="card-body pad30">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <Accordion activeIndex={0}>
                                                    <AccordionTab header="ಪ್ರ.1 ಪ್ರೋಗ್ರಾಂಗಾಗಿ ನಾನು ಹೇಗೆ ನೋಂದಾಯಿಸಿಕೊಳ್ಳುವುದು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ & ಫಿಟ್ಟಿಂಗ್ಸ್ ಸೂಪರ್ ಪ್ಲಂಬರ್ ಲಾಯಲ್ಟಿ ಪ್ರೋಗ್ರಾಂ <br />
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.2 ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ ಸೂಪರ್ ಪ್ಲಂಬರ್ ಲಾಯಲ್ಟಿ ಕಾರ್ಯಕ್ರಮದಲ್ಲಿ ಯಾರು ಭಾಗವಹಿಸಬಹುದು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಉತ್ತರ.  ಮಾನ್ಯವಾದ ಆಧಾರ್ ಕಾರ್ಡ್ ಹೊಂದಿರುವ ಮತ್ತು 18 ವರ್ಷಕ್ಕಿಂತ ಮೇಲ್ಪಟ್ಟ ಯಾವುದೇ ಪ್ಲಂಬರ್. ಅಧಿಕೃತ ರಿಟೇಲರ್‌ಗಳಿಂದ ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ & ಫಿಟ್ಟಿಂಗ್ಸ್ ಉತ್ಪನ್ನಗಳನ್ನು ಖರೀದಿಸುವವರು ಈ ಕಾರ್ಯಕ್ರಮದಲ್ಲಿ ಭಾಗವಹಿಸಲು ಅರ್ಹರಾಗಿರುತ್ತಾರೆ 
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.3 ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ & ಫಿಟ್ಟಿಂಗ್ಸ್ ಸೂಪರ್ ಪ್ಲಂಬರ್ ಲಾಯಲ್ಟಿ ಪ್ರೋಗ್ರಾಂ ಅನ್ನು ನಾನು ಹೇಗೆ ಡೌನ್‌ಲೋಡ್ ಮಾಡುವುದು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಆಂಡ್ರಾಯ್ಡ್ ಬಳಕೆದಾರರು ಸರಳವಾಗಿ ಗೂಗಲ್ ಪ್ಲೇ ಸ್ಟೋರ್‌ಗೆ ಹೋಗಿ ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ ಸೂಪರ್ ಪ್ಲಂಬರ್ ಲಾಯಲ್ಟಿ ಆ್ಯಪ್ ಅನ್ನು ಹುಡುಕಬಹುದು ಮತ್ತು ನಂತರ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಬಹುದು.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.4 ನನ್ನ ಖಾತೆಗೆ ನಾನು ಹೇಗೆ ಲಾಗ್ ಇನ್ ಮಾಡುವುದು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ನೋಂದಣಿ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ, ನೀವು ಪ್ರೋಗ್ರಾಂಗೆ ದಾಖಲಾಗುತ್ತೀರಿ. ನಿಮ್ಮ ನೋಂದಾಯಿತ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ ಮತ್ತು ಒಟಿಪಿಯೊಂದಿಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.5 ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ & ಫಿಟ್ಟಿಂಗ್ಸ್ ಸೂಪರ್ ಪ್ಲಂಬರ್ ಆ್ಯಪ್ ಪ್ರೋಗ್ರಾಂನಲ್ಲಿ ನಾನು ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಗಳಿಸುವುದು ಹೇಗೆ? ">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span> ಅಧಿಕೃತ ರಿಟೇಲರ್‌ಗಳಿಂದ ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ & ಫಿಟ್ಟಿಂಗ್ಸ್ ಉತ್ಪನ್ನಗಳನ್ನು ಖರೀದಿಸುವ ಮೂಲಕ ಮತ್ತು ಆ್ಯಪ್ ನಲ್ಲಿ ಇನ್‌ವಾಯ್ಸ್ ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡುವ ಮೂಲಕ ನೀವು ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಗಳಿಸಬಹುದು. ನಿಮ್ಮ ಖರೀದಿಯ ಪುರಾವೆಯ ಯಶಸ್ವಿ ಮೌಲ್ಯೀಕರಣದ ನಂತರ, ಇನ್‌ವಾಯ್ಸ್ ಮೊತ್ತದ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ನಿಮ್ಮ ಖಾತೆಗೆ ಸೇರಿಸಲಾಗುತ್ತದೆ. 
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.6 ಈ ಪ್ರೋಗ್ರಾಂ ರಿಟೇಲ್ ಮತ್ತು ಪ್ರಾಜೆಕ್ಟ್ ಆರ್ಡರ್‌ಗಳಿಗೆ ಆಗಿದೆಯೇ?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಇಲ್ಲ. ಪ್ರೋಗ್ರಾಂ ರಿಟೇಲ್ ಆರ್ಡರ್‌ಗಳಿಗೆ ಮಾತ್ರ
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.7 ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ ಸೂಪರ್ ಪ್ಲಂಬರ್ ಪ್ರೋಗ್ರಾಂನಲ್ಲಿ ಪಾಯಿಂಟ್‌ಗಳ ಹಂಚಿಕೆಗೆ ಯಾವ ಉತ್ಪನ್ನಗಳು/SKUಗಳು ಅರ್ಹವಾಗಿವೆ?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್‌ಗಳ ಪ್ರತಿಯೊಂದು ಉತ್ಪನ್ನಕ್ಕೂ ಇದು ಅನ್ವಯಿಸುತ್ತದೆ. ನೀವು ಇನ್‌ವಾಯ್ಸ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಬೇಕು ಮತ್ತು ಆ್ಯಪ್ ನಲ್ಲಿ ಫಿನೋಲೆಕ್ಸ್ ಉತ್ಪನ್ನ ಖರೀದಿ ಮೌಲ್ಯವನ್ನು ನಮೂದಿಸಬೇಕು. ಖರೀದಿ ಮೌಲ್ಯದ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ನಿಮ್ಮ ಖಾತೆಗೆ ಪಾಯಿಂಟ್‌ಗಳಾಗಿ ಸೇರಿಸಲಾಗುತ್ತದೆ.<br />
                                                            ಅರ್ಹ ಉತ್ಪನ್ನಗಳ ಕುರಿತು ಹೆಚ್ಚಿನ ವಿವರಗಳಿಗಾಗಿ, ನೀವು ಸೋಮವಾರದಿಂದ ಶನಿವಾರದ ವರೆಗೆ 9.30 ರಿಂದ ಸಂಜೆ 5.30 ರ ನಡುವೆ ನಮ್ಮ ಪ್ರೋಗ್ರಾಂನ ಸಹಾಯವಾಣಿ 18002024289 ನಲ್ಲಿ ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಬಹುದು.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.8 ನನ್ನ ಖಾತೆಯಲ್ಲಿನ ರಿವಾರ್ಡ್ ಪಾಯಿಂಟ್‌ಗಳು ನಾನು ಮಾಡಿದ ವರದಿ ಮಾಡಿದ ಖರೀದಿಗಳಿಗಿಂತ ಕಡಿಮೆಯಿದ್ದರೆ ಏನು ಮಾಡಬೇಕು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಸೋಮವಾರದಿಂದ ಶನಿವಾರದ ವರೆಗೆ 9.30 ರಿಂದ ಸಂಜೆ 5.30 ರ ವರೆಗೆ 18002024289 ನಮ್ಮ ಕಾರ್ಯಕ್ರಮದ ಹೆಲ್ಪ್‌ಡೆಸ್ಕ್ ದೊಂದಿಗೆ ಸಂಪರ್ಕ ಸಾಧಿಸಿ. ಪರ್ಯಾಯವಾಗಿ, ಹೆಚ್ಚಿನ ಸ್ಪಷ್ಟತೆ ಪಡೆಯಲು ನೀವು ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ ಮಾರಾಟ ತಂಡದೊಂದಿಗೆ ಸಂಪರ್ಕದಲ್ಲಿರಬಹುದು
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.9 ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ & ಫಿಟ್ಟಿಂಗ್ಸ್ ಸೂಪರ್ ಪ್ಲಂಬರ್ ಲಾಯಲ್ಟಿ ಪ್ರೋಗ್ರಾಂನಲ್ಲಿ ಪ್ರತಿ ರಿವಾರ್ಡ್ ಪಾಯಿಂಟ್‌ನ ಮೌಲ್ಯ ಏನು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಒಂದು (01) ರಿವಾರ್ಡ್ ಪಾಯಿಂಟ್‌ = 0.02 ರೂಪಾಯಿಗಳು
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.10 ಬೋನಸ್ ಪಾಯಿಂಟ್‌ಗಳು:">
                                                        <p className="m-0">
                                                            1. ದೃಢೀಕರಣದ ನಂತರ, ಸೇರುವ ಬೋನಸ್ ಆಗಿ 10,000 ಪಾಯಿಂಟ್‌ಗಳನ್ನು ನೀವು ಸ್ವೀಕರಿಸುತ್ತೀರಿ<br />
                                                            2. ಮೊದಲ ರಿಡೆಂಪ್ಶನ್ ವಿನಂತಿಗಾಗಿ ಬೋನಸ್ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಬಳಸಲಾಗುವುದಿಲ್ಲ<br />
                                                            3. ನಂತರದ ರಿಡೆಂಪ್ಶನ್ ವಿನಂತಿಯಲ್ಲಿ ನೀವು ರಿಡೆಂಪ್ಶನ್‌ಗಾಗಿ ಬೋನಸ್ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಬಳಸಬಹುದು <br />                                                       </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.11 ನಾನು ಗಳಿಸಿದ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಮತ್ತೊಂದು ನೋಂದಾಯಿತ ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ & ಫಿಟ್ಟಿಂಗ್ಸ್ ಸೂಪರ್ ಪ್ಲಂಬರ್ ಲಾಯಲ್ಟಿ ಪ್ರೋಗ್ರಾಂ ಖಾತೆಗೆ ವರ್ಗಾಯಿಸಬಹುದೇ??">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಇಲ್ಲ, ನೀವು ಯಾವುದೇ ನೋಂದಾಯಿತ ಸದಸ್ಯರಿಗೆ ನಿಮ್ಮ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ವರ್ಗಾಯಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.12 ನನ್ನ ಬ್ಯಾಲೆನ್ಸ್ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ನಾನು ಹೇಗೆ ಪರಿಶೀಲಿಸುವುದು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿರುವ 'ಹ್ಯಾಂಬರ್ಗರ್' ಟ್ಯಾಬ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ ನಿಮ್ಮ ಬ್ಯಾಲೆನ್ಸ್ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ನೀವು ಪರಿಶೀಲಿಸಬಹುದು.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.13 ಪಾಯಿಂಟ್‌ಗಳ ವ್ಯಾಲಿಡಿಟಿ ಏನು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಅದನ್ನು ಸಿದ್ಧಪಡಿಸಿದ ದಿನಾಂಕದಿಂದ 3 (ಮೂರು) ವರ್ಷಗಳು.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.14 ರಿಡೆಂಪ್ಷನ್ ಗೆ ಅಗತ್ಯವಿರುವ ಕನಿಷ್ಠ ಪಾಯಿಂಟ್‌ಗಳು ಎಷ್ಟು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಮೊದಲ ರಿಡೆಂಪ್ಶನ್ ಅನ್ನು 20000 ಪಾಯಿಂಟ್‌ಗಳಲ್ಲಿ ಮಾಡಲಾಗುತ್ತದೆ ನಂತರ ಮುಂದಿನ ರಿಡೆಂಪ್ಶನ್‌ಗಳನ್ನು ಮಾಡಲು ಕನಿಷ್ಠ 5000 ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಮಾಡಲಾಗುತ್ತದೆ
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.15 ನನ್ನ ಗಳಿಸಿದ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ನಾನು ಹೇಗೆ ರಿಡೀಮ್ ಮಾಡುವುದು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ನಿಮ್ಮ ಸಂಗ್ರಹಿಸಿದ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಪಡೆದುಕೊಳ್ಳಲು ವಿವಿಧ ಆಯ್ಕೆಗಳಿವೆ. ಈ ಕೆಳಗಿನಂತೆ ಉಲ್ಲೇಖಿಸಲಾಗಿದೆ:<br />
                                                            1. ಆಧಾರ್ ನೊಂದಿಗೆ ಲಿಂಕ್ ಮಾಡಲಾದ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯ ಪೇಟಿಎಂ ವ್ಯಾಲೆಟ್ ವರ್ಗಾವಣೆ<br />
                                                            2. ನೇರ ಬ್ಯಾಂಕ್ ವರ್ಗಾವಣೆ<br />
                                                            3. ಯುಪಿಐ ವರ್ಗಾವಣೆ<br />
                                                            4. ಇ-ಗಿಫ್ಟ್ ವೋಚರ್‌ಗಳು<br />
                                                            5. ಫಿನೋಲೆಕ್ಸ್ ಮರ್ಚಂಡೈಸ್ ವಸ್ತುಗಳು
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.16 ನನ್ನ ಸಂಚಿತ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ನಾನು ಎಷ್ಟು ಬಾರಿ ರಿಡೀಮ್ ಮಾಡಬಹುದು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ನಿಮ್ಮ ಖಾತೆಯಲ್ಲಿ ಕನಿಷ್ಠ 5000 ಸಾಕಷ್ಟು ಬ್ಯಾಲೆನ್ಸ್ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಹೊಂದಿದ್ದರೆ, ಪ್ರೋಗ್ರಾಂ ಅವಧಿಯುದ್ದಕ್ಕೂ ನೀವು ರಿಡೆಂಪ್ಶನ್ ಅನ್ನು ವಿನಂತಿಸಬಹುದು.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.17 ನನ್ನ ರಿಡೀಮ್ ಮಾಡಿದ ಬಹುಮಾನದ ಸ್ಥಿತಿಯನ್ನು ನಾನು ಹೇಗೆ ತಿಳಿಯುವುದು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ರಿಡೀಮ್ ಪಾಯಿಂಟ್‌ಗಳು  ಟ್ರ್ಯಾಕ್ ರಿವಾರ್ಡ್‌ಗಳ ಅಡಿಯಲ್ಲಿ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ನಿಮ್ಮ ರಿಡೆಂಪ್ಶನ್ ಸ್ಥಿತಿಯನ್ನು ನೀವು ಪರಿಶೀಲಿಸಬಹುದು.<br />
                                                            ನಿಮ್ಮ EGV/UPI/Paytm ವ್ಯಾಲೆಟ್ ವರ್ಗಾವಣೆ/ನೇರ ಬ್ಯಾಂಕ್ ವರ್ಗಾವಣೆ ಯಶಸ್ವಿಯಾದ ನಂತರ ನೀವು SMS ಅಪ್‌ಡೇಟ್ ಅನ್ನು ಸಹ ಸ್ವೀಕರಿಸುತ್ತೀರಿ.<br />
                                                            ಆದಾಗ್ಯೂ, ನಿಮ್ಮ ರಿಡೆಂಪ್ಷನ್ ನ ಪ್ರಸ್ತುತ ಸ್ಥಿತಿಯನ್ನು ತಿಳಿಯಲು ನೀವು ಪ್ರೋಗ್ರಾಂನ ಸಹಾಯವಾಣಿಯನ್ನು 18002024289 ನಲ್ಲಿ ಸೋಮವಾರದಿಂದ-ಶನಿವಾರದವರೆಗೆ (09.30 ರಿಂದ 05.30 ರವರೆಗೆ) ಸಂಪರ್ಕಿಸಬಹುದು.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.18 ನಾನು ಸಲ್ಲಿಸಿದ ಖರೀದಿಗಳಿಗೆ ನಾನು ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಪಡೆದಿದ್ದೇನೆ ಎಂದು ನನಗೆ ಹೇಗೆ ತಿಳಿಯುತ್ತದೆ?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಉತ್ತರ. ಇನ್‌ವಾಯ್ಸ್ ಕ್ಲಿಯರ್ ಇಮೇಜ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಪ್‌ಲೋಡ್ ಮಾಡಿದ ನಂತರ, ಪಾಯಿಂಟ್‌ಗಳು ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ಲೆಕ್ಕಪರಿಶೋಧನೆ ಮಾಡದ ಪಾಯಿಂಟ್‌ಗಳಾಗಿ ಗೋಚರಿಸುತ್ತವೆ ಮತ್ತು ನೀವು ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ ಉತ್ಪನ್ನಗಳನ್ನು ಖರೀದಿಸಿದ ಆಯಾ ಅಧಿಕೃತ ರಿಟೇಲರ್ ನಿಂದ ಅನುಮೋದನೆ ಪಡೆದ ನಂತರವೇ ರಿಡೆಂಪ್ಶನ್‌ಗೆ ಲಭ್ಯವಿರುತ್ತದೆ.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.19 ನನ್ನ ರಿಡೀಮ್ ಮಾಡಿದ ಉತ್ಪನ್ನವನ್ನು ಸ್ವೀಕರಿಸಲು ಎಷ್ಟು ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ?">
                                                        <p className="m-0">
                                                            • UPI ವರ್ಗಾವಣೆಗಳು ವಿತರಣೆಯಾಗಲು 24 ರಿಂದ 48 ಗಂಟೆಗಳನ್ನು ಅಗತ್ಯವಿರುತ್ತದೆ<br />
                                                            • ನೇರ ಬ್ಯಾಂಕ್ ವರ್ಗಾವಣೆಗಳನ್ನು ತಲುಪಿಸಲು 24 ರಿಂದ 48 ಗಂಟೆಗಳವರೆಗೆ ಅಗತ್ಯವಿರುತ್ತದೆ<br />
                                                            • Paytm ವ್ಯಾಲೆಟ್ ವರ್ಗಾವಣೆಯನ್ನು ತಲುಪಲು 24 ರಿಂದ 48 ಗಂಟೆಗಳು ಅಗತ್ಯವಿರುತ್ತದೆ<br />
                                                            • ಇ-ಗಿಫ್ಟ್ ವೋಚರ್‌ಗಳನ್ನು ನೈಜ-ಸಮಯದ ಆಧಾರದ ಮೇಲೆ ನೀಡಲಾಗುತ್ತದೆ ಮತ್ತು ಗರಿಷ್ಠ 24 ಗಂಟೆಗಳ ಒಳಗೆ ಆ್ಯಪ್ ನಲ್ಲಿ ಪ್ರತಿಫಲಿಸುತ್ತದೆ<br />
                                                            • ಸ್ಟಾಕ್ ಲಭ್ಯತೆ ಮತ್ತು ವಿತರಣಾ ಸ್ಥಳವನ್ನು ಅವಲಂಬಿಸಿ ಫಿನೋಲೆಕ್ಸ್ ಮರ್ಚಂಡೈಸ್ 2-3 ವಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.20 ಕ್ರೆಡಿಟ್ ಆಗಿರುವ ನನ್ನ ಪಾಯಿಂಟ್‌ಗಳು ತಪ್ಪಾಗಿದ್ದರೆ ಅಥವಾ ನನ್ನ ಭಾಗವಹಿಸುವಿಕೆಗಾಗಿ ನನಗೆ ಪಾಯಿಂಟ್‌ಗಳನ್ನು ನಿಯೋಜಿಸಲಾಗಿಲ್ಲದೇ ಇದ್ದರೆ, ಆಗ ನಾನು ಏನು ಮಾಡಬಹುದು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಅಂತಹ ಸನ್ನಿವೇಶದಲ್ಲಿ, ನೀವು ಆ್ಯಪ್ ನಿಂದ ನಿಮ್ಮ ಆತಂಕವನ್ನು ಸಲ್ಲಿಸಬಹುದು ಅಥವಾ ನಿಮ್ಮ ನೋಂದಾಯಿತ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯೊಂದಿಗೆ ನಮ್ಮ ಸಹಾಯವಾಣಿ 18002024289 ಗೆ ಕರೆ ಮಾಡಬಹುದು ಮತ್ತು ನಿಮ್ಮ ಆತಂಕದ ಕುರಿತು ವಿನಂತಿಯನ್ನು ನೀಡಬಹುದು. ನಾವು ಸಮಸ್ಯೆಯನ್ನು ಪರಿಶೀಲಿಸುತ್ತೇವೆ ಮತ್ತು ಸಾಧ್ಯವಾದಷ್ಟು ಬೇಗ ಉತ್ತರಿಸುತ್ತೇವೆ.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.21 ನಾನು ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ & ಫಿಟ್ಟಿಂಗ್ಸ್ ಸೂಪರ್ ಪ್ಲಂಬರ್ ಕಾರ್ಯಕ್ರಮ ಕೇಂದ್ರವನ್ನು ಹೇಗೆ ಸಂಪರ್ಕಿಸಬುಹುದು?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ನೀವು ನಮ್ಮ ಕಾರ್ಯಕ್ರಮದ ಸಹಾಯವಾಣಿ ಸಂಖ್ಯೆ 18002024289 ನಲ್ಲಿ ಸೋಮವಾರದಿಂದ ಶನಿವಾರದ ವರೆಗೆ ಪೂರ್ವಾಹ್ನ 9:30 ರಿಂದ ಅಪರಾಹ್ನ 5:30 ವರೆಗೆ ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಬಹುದು.
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ಪ್ರ.22  ಫಿನೋಲೆಕ್ಸ್ ಪೈಪ್ಸ್ & ಫಿಟ್ಟಿಂಗ್ಸ್ ಸೂಪರ್ ಪ್ಲಂಬರ್ ಲಾಯಲ್ಟಿ ಪ್ರೊಗ್ರಾಂ ನ ಇತರ ಕೆಲವು ಪ್ರಯೋಜನಗಳನ್ನು ನೀವು ದಯವಿಟ್ಟು ತಿಳಿಸಬಹುದೇ?">
                                                        <p className="m-0">
                                                            <span>ಉತ್ತರ:</span>ಹೌದು, ಪ್ಲಂಬರ್‌ಗಳು ಮತ್ತು ಅವರ ಕುಟುಂಬಗಳಿಗೆ ಒಂದಕ್ಕಿಂತ ಹೆಚ್ಚು ತೊಡಗಿಕೊಳ್ಳುವಿಕೆಯ ಚಟುವಟಿಕೆಗಳು ಇರುತ್ತವೆ.
                                                        </p>
                                                    </AccordionTab>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </section >
    )
}
