import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
export default function SalesFaq() {
    return (
        <>
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Tech FAQ's</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="carrd1 requestbox">
                                    <div className="card-body pad30">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <Accordion activeIndex={0}>
                                                        <AccordionTab header="Q. 1 Unable to log in? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>1. Check your internet connection<br />
                                                                2. Verify your login credential<br />
                                                                3. Reset your device<br />
                                                                If the issue is still not resolve Use the "Forgot Password" option to reset your password.
                                                                Or else reach us at our toll free number i.e.  18002024289 from Monday to Saturday 9.30 am to 5.30 pm
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header=".Q. 2 App is not downloading and return back to the home page? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>1. Check the internet connectivity<br />

                                                                2. Clear cache and data.<br />

                                                                3.  Check the software update<br />

                                                                4. Restart your device<br />

                                                                5. May be your device is not supported this app that the reason you are not able to download the app.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header=" Q. 3 Plumber is facing the Bill/Invoice Upload issue? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>Although we have change the coding since then we did not received any issue related to  Invoice Upload. But if the plumber still facing the issue then please follow below pointers.<br />

                                                                1. Ensure that plumber device is connected to a stable and active internet connection.<br />

                                                                2. Please download the latest version of app via play store.<br />

                                                                3. Make a video and send this on our WhatsApp number 9310366767 or else you can reach us on our toll free number i.e.  18002024289 from Monday to Saturday 9.30 am to 5.30 pm.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. 4 Plumber Loyalty Points are Not Updating? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>1. Ensure that plumber has downloaded the latest version of app.<br />

                                                                2. Check the approval status in app if the plumber sales is pending for approval than please connect with the concern retailer and asked him to approved the pending proof of purchases of a plumber.<br />

                                                                3. In case still the issue is not resolve please connect with us on our toll free number and you can also share the screenshot via WhatsApp.<br />
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. 5 App Features Not Working as Expected? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>1.  Ensure that you are using the latest version of the application.<br />

                                                                2. Close the app completely and restart it again it might be a temporary glitch.<br />

                                                                3. Email the screenshot and a brief description of the issue to cc@finolexsuperplumber.com.
                                                                Our technical support team will assist you promptly.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. 6. If a plumber is not getting OTP? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>1. Check Mobile Network Signal<br />

                                                                2. Ensure that he is login with the same Registered Mobile Number.<br />

                                                                3. Wait and Retry after sometime and then click on the option resend OTP.<br />

                                                                4. Check if your phone is in "Do Not Disturb" mode, as this can block incoming SMS.<br />

                                                                5. Ensure that your SMS inbox is not full. If it is, delete some messages to make room for new ones.<br />

                                                                6. Make a video and send this on our WhatsApp number 9310366767 or else you can reach us on our toll free number i.e.  18002024289 from Monday to Saturday 9.30 am to 5.30 pm.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q.  7.  If a plumber is facing issue in KYC Verification? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>1. Ensure his registered mobile number is linked with the Aadhaar Number.<br />

                                                                2. Check the approval status in app if the plumber first proof of purchase is pending than please Login and approved the pending proof of purchase of a plumber.<br />

                                                                3. Make a video and send this on our WhatsApp number 9310366767 or else you can reach us on our toll free number i.e.  18002024289 from Monday to Saturday 9.30 am to 5.30 pm.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. 8 My Dashboard Count is incorrect? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>1. Ensure that you have updated the latest version of the application.<br />

                                                                2. Email the screenshot and a brief description of the issue to cc@finolexsuperplumber.com. Our technical support team will assist you promptly.<br />

                                                                3. Make a video and send this on our WhatsApp number 9310366767 or else you can reach us on our toll free number i.e.  18002024289 from Monday to Saturday 9.30 am to 5.30 pm.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. 9 Plumber is not getting Redemptions? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>1. Request you to please mail us the plumber details to cc@finolexsuperplumber.com and we will share the updated status of the plumber redemptions.<br />

                                                                2. You can reach us on our toll free number i.e.  18002024289 from Monday to Saturday 9.30 am to 5.30 pm.<br /><br />
                                                                <b> General Tips for the Plumber which help you to guide them. </b><br /><br />
                                                                <b>Device Compatibility:</b> <br />

                                                                Confirm that your device meets the minimum system requirements for the application.<br />

                                                                <b>Permissions:</b> <br />

                                                                Make sure the app has the necessary permissions to access features like camera, location, and notifications.<br />

                                                                <b>Reinstall the App:</b> <br />

                                                                If all else fails, uninstall the app, restart your device, and reinstall the latest version from the app store.
                                                            </p>
                                                        </AccordionTab>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
            <Footer />
        </>
    )
}
