import React, { useState, useEffect } from 'react'
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import longarrow from '../../../../assets/img/longarrow.svg'
import { useNavigate } from 'react-router-dom'
import * as Constants from "../../../../Constants/index"
import axios from 'axios'
import Oval from '../../Loader/CircleLoade';
import swal from 'sweetalert'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/minimal.css';
export default function ApprovedRetailer() {
    const navigate = useNavigate()
    let gst = localStorage.getItem('gst')
    let mobile = localStorage.getItem('user_mobile')
    let monthName = localStorage.getItem('month_name')
    const [results, setResults] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [loading, setLoading] = useState(false);
    const [searchRecord, setSearchRecord] = useState()
    const [isVisible, setIsVisible] = useState(false);
    const [searchUser, setSearchUser] = useState('')
    const [limit] = useState('10')


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        if (currentPage !== 1) {
            approvedRegistrationData(searchUser);
        } else {
            approvedRegistrationData('');
        } return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [currentPage]);

    const approvedRegistrationData = async (userName) => {
        let token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
        } else {
            const searchUserVal = userName === '' ? '' : `&name=${userName}`
            try {
                setLoading(true)
                await axios.get(Constants.baseUrl + `capture/invoice/month_year/allinvoice?gst=${gst}&mobile=${mobile}&monthYear=${monthName}&status=APPROVED${searchUserVal}&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        program: Constants.program,
                    }
                })
                    .then((res) => {
                        if (res.status === 201) {
                            if (res.data.code === 10001) {
                                setLoading(false);
                                setResults(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                            } else if (res.data.code === 12002) {
                                setLoading(false);
                                setSearchRecord(res.data.output.results)
                            } else {
                                swal({
                                    text: res.data.msg,
                                    icon: 'error',
                                    timer: 2000,
                                    buttons: false,
                                })
                            }
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    })

            } catch (error) {
                console.log(error)
            }
        }

    };

    const handleSearchQuery = (event) => {
        setSearchUser(event.target.value)
        if (event.target.value === '') {
            approvedRegistrationData('')
            setSearchRecord()
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            approvedRegistrationData(searchUser);
        }
    };

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Approved  </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row clearfix mb-15 callwell">
                                    <div className="col-md-3 col-sm-12 col-xs-8">
                                        <input className="frmcntrl" placeholder="Name/Mobile Number" type="text" value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-4">
                                        <button type="button" className="btn login_white_btn" disabled={searchUser.trim() === ''}
                                            onClick={() => approvedRegistrationData(searchUser)}> Search <img src={longarrow} alt='' /></button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="carrd1">
                                            <div className="card-body table-responsive">
                                                <table className="table table-bordered mytable mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Plumber Claim Id</th>
                                                            <th>Plumber Name</th>
                                                            <th>Plumber Mobile No. </th>
                                                            <th>Date of Upload</th>
                                                            <th>Total Earned Points</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="7" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {results && results.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                // const formattedDate = moment(item.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            <td>{item.id}</td>
                                                                            <td>{item.user.name}</td>
                                                                            <td>{item.user.mobile}</td>
                                                                            <td>{item.createDateTime}</td>
                                                                            <td>{item.user.totalPoints}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="modalapprove" className="modal fade modal-blue" role="dialog">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-details">

                                <div className="row">
                                    <div className="form-group col-lg-12  col-md-12">
                                        <label>Select Status<span style={{ color: 'red' }}></span></label>
                                        <select className="form-control2">
                                            <option value="">Select Status</option>
                                            <option value="Approve">Approve</option>
                                            <option value="Reject">Reject</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12  col-md-12">
                                        <label>Enter Reason<span style={{ color: 'red' }}>*</span></label>
                                        <textarea className="form-control txtreason" cols="50" rows="4" placeholder="Enter Reason"></textarea>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 text-center">
                                        <input type="submit" className="btn login_btn" value="Approve" />
                                        <input type="submit" className="btn btn-reject" value="Reject" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Link id="scrollUp" to="" className={`scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`} style={{ position: 'fixed', zIndex: '2147483647' }} onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp} /></Link>
            <Footer />
        </>
    )
}
