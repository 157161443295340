import React, { useState } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom'
import ResponsivePagination from 'react-responsive-pagination';
import Oval from '../Loader/CircleLoade';
import longarrow from '../../../assets/img/longarrow.svg'
// import * as Constants from "../../../Constants/index"
// import axios from 'axios'
// import swal from 'sweetalert'
export default function PenApprovalClaimIdReport() {
    const [results, setResults] = useState([])
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchRecord, setSearchRecord] = useState(null)
    // const [limit] = useState('10')
    const [loading, setLoading] = useState(false);
    // const [searchUser, setSearchUser] = useState('')


    // useEffect(() => {
    //     let token = localStorage.getItem('token');
    //     try {
    //         setLoading(true);
    //         axios.get(Constants.baseUrl + `reports/users/list?userType=MEMBER&sortBy=name&sortAsc=true&limit=${limit}&page=${currentPage}`, {

    //             headers: {
    //                 Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
    //                 appVersion: Constants.av,
    //                 platform: Constants.pt,
    //                 company: Constants.company,
    //                 program: Constants.program,
    //             }
    //         })
    //             .then((res) => {
    //                 if (res.status === 201) {
    //                     if (res.data.code === 10001 && res.data.output) {
    //                         setResults(res.data.output.results)
    //                         setTotalPages(res.data.output.totalPages);
    //                         setSearchRecord()
    //                         setLoading(false);
    //                     } else if (res.data.code === 12002 && res.data.output) {
    //                         setLoading(false);
    //                         setSearchRecord(res.data.output.results)
    //                         setTotalPages(res.data.output.totalPages);
    //                     } else {
    //                         swal({
    //                             text: res.data.msg,
    //                             icon: 'error',
    //                             timer: 2000,
    //                             buttons: false,
    //                         })
    //                     }
    //                 } else {
    //                     swal({
    //                         text: res.data.msg,
    //                         icon: 'error',
    //                         timer: 2000,
    //                         buttons: false,
    //                     })
    //                 }
    //             })

    //     } catch (error) {
    //         console.log(error)
    //     }

    // }, [currentPage])
    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Pending for Approval Claim Id Report  </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row clearfix mb-15 callwell">
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <input className="frmcntrl" placeholder="Name/Mobile no." type="text" />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                        <button type="button" className="btn login_white_btn"> Search <img src={longarrow} alt='' /></button>
                                    </div>
                                    {/* <div className="col-md-3 col-sm-3 col-xs-12">
                                    <h4 className="btn login_white_btn"><i class="fa fa-file-excel-o"></i> Export</h4>
                                </div> */}
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="carrd1">
                                            <div className="card-body table-responsive">
                                                <table className="table table-bordered mytable mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Plumber Name</th>
                                                            <th>Plumber Mobile No. </th>
                                                            <th>City</th>
                                                            <th>Retailer Name</th>
                                                            <th>Retailer Shop Name</th>
                                                            <th>Registration Date</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="7" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {results && results.map((item, index) => {
                                                                // const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                // const formattedDate = moment(item.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}

                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>

            </section>
            <Footer />
        </>)
}
