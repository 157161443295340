import React, { useState, useEffect } from 'react'
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import longarrow from '../../../../assets/img/longarrow.svg'
import { useNavigate } from 'react-router-dom'
import * as Constants from "../../../../Constants/index"
import axios from 'axios'
import Oval from '../../Loader/CircleLoade';
import swal from 'sweetalert'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/minimal.css';
import moment from 'moment';
export default function Registered() {
    const navigate = useNavigate()
    let monthName = localStorage.getItem('month_name')

    const [results, setResults] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [loading, setLoading] = useState(false);
    const [searchRecord, setSearchRecord] = useState()
    const [isVisible, setIsVisible] = useState(false);
    const [searchUser, setSearchUser] = useState('')
    const [limit] = useState('10')



    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        if (currentPage !== 1) {
            approvedRegistrationData(searchUser);
        } else {
            approvedRegistrationData('');
        } return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [currentPage]);

    const approvedRegistrationData = async (userName) => {
        let token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
        } else {
            const searchUserVal = userName === '' ? '' : `&name=${userName}`
            try {
                setLoading(true)
                await axios.get(Constants.baseUrl + `user/month_year/user?monthYear=${monthName}${searchUserVal}&userType=MEMBER&status=APPROVED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        program: Constants.program,
                    }
                })
                    .then((res) => {
                        if (res.status === 201) {
                            if (res.data.code === 10001) {
                                setLoading(false);
                                setResults(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                            } else if (res.data.code === 12002) {
                                setLoading(false);
                                setSearchRecord(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                            } else {
                                swal({
                                    text: res.data.msg,
                                    icon: 'error',
                                    timer: 2000,
                                    buttons: false,
                                })
                            }
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    })

            } catch (error) {
                console.log(error)
            }
        }

    };

    const handleSearchQuery = (event) => {
        setSearchUser(event.target.value)
        if (event.target.value === '') {
            approvedRegistrationData('')
            setSearchRecord()
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            approvedRegistrationData(searchUser);
        }
    };

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <>
            {loading ? <Oval /> : null}

            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Registered  </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row clearfix mb-15 callwell">
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <input className="frmcntrl" placeholder="Name/Mobile no." type="text" value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                        <button type="button" className="btn login_white_btn" disabled={searchUser.trim() === ''}
                                            onClick={() => approvedRegistrationData(searchUser)}> Search <img src={longarrow} alt='' /></button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="carrd1">
                                            <div className="card-body table-responsive">
                                                <table className="table table-bordered mytable mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Plumber Name</th>
                                                            <th>Plumber Mobile No. </th>
                                                            <th>City</th>
                                                            <th>Retailer Name</th>
                                                            <th>Retailer Shop Name</th>
                                                            <th>Registration Date</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="7" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {results && results.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                const formattedDate = moment(item.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.mobile}</td>
                                                                            <td>{item.addressWork.workCity}</td>
                                                                            <td>{item?.retailerName}</td>
                                                                            <td>{item?.retailerFirmName}</td>
                                                                            <td>{formattedDate}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}

                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Link id="scrollUp" to="" className={`scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`} style={{ position: 'fixed', zIndex: '2147483647' }} onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp} /></Link>
            <Footer />
        </>
    )
}
