import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import * as Constants from "../../../../Constants/index"
import swal from 'sweetalert';
import Modal from 'react-modal';
import OtpInput from 'react-otp-input';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import '../Registration/registration.css'
const customStyles = {
    content: {
        top: '35%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'none',
        border: 'none',
    },
};
export default function Registration() {
    const [stateFetch, setStateFetch] = useState([])
    const [districtFetch, setDistrictFetch] = useState([])
    const [cityFetch, setCityFetch] = useState([])
    const [mobileOtp, setMobileOtp] = useState('')
    const [mobileModalShow, setMobileModalShow] = useState(false)
    const [errors, setErrors] = useState({});
    const [bankDetailShow, setBankDetailShow] = useState('bankdetails')
    // const [upiDetailsShow, setUpiDetailsShow] = useState('')
    const [showUpArrow, setShowUpArrow] = useState(false);



    // registraion state
    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    // const [email, setEmail] = useState('')
    const [alternateMobile, setAlternateMobile] = useState('')
    const [whatsappNumber, setWhatsappNumber] = useState('')
    const [state, setState] = useState('')
    const [stateId, setStateId] = useState()
    const [district, setDistrict] = useState('')
    const [districtId, setDistrictId] = useState()
    const [city, setCity] = useState('')
    const [cityId, setCityId] = useState()
    const [pincode, setPincode] = useState('')
    const [gender, setGender] = useState('')
    const [dob, setDob] = useState('')
    const [photo, setPhoto] = useState('')
    const [aadharNumber, setAadharNumber] = useState('')
    const [aadharFrontUrl, setAadharFrontUrl] = useState('')
    const [aadharBackUrl, setAadharBackUrl] = useState('')
    const [panNumber, setPanNumber] = useState('');
    const [panFrontUrl, setPanFrontUrl] = useState('')



    //bank details
    const [accountNo, setAccountNo] = useState('')
    const [holderName, setHolderName] = useState('')
    const [accountType, setAccountType] = useState('')
    const [bankName, setBankName] = useState('')
    const [ifsc, setIfsc] = useState('')
    const [cancelChk, setCancelChk] = useState('')
    const [upiId, setUpiId] = useState('')
    const [upiIdScreen, setUpiIdScreen] = useState('')

    const formattedDob = moment(dob).format('DD-MM-YYYY')

    // Calculate the exact date 18 years ago from today
    const exactMinDate = new Date();
    exactMinDate.setFullYear(exactMinDate.getFullYear() - 18);
    exactMinDate.setHours(0, 0, 0, 0);


    useEffect(() => {
        // call state api for get state name

        let token = localStorage.getItem('token');

        try {
            axios.get(Constants.baseUrl + `master/geography/state?limit=1000`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                }
            })
                .then((res) => {
                    if (res.status === 201 && res.data.code === 10001) {
                        setStateFetch(res.data.output.results)
                    } else {
                        // swal(res.data.msg);
                    }
                })

        } catch (error) {
            console.log(error)
        }
    }, []);


    // call state api by state ID for get district name
    const handleStateChange = async (event) => {
        const selectedId = event.target.value
        setDistrictFetch([])
        setCityFetch([])
        setDistrict('')
        setCity('')
        setPincode('')
        if (selectedId === '0') {
            setState('')
            setDistrict('')
            setCity('')
        } else {
            districtData(selectedId);
        }
    };

    let token = localStorage.getItem('token');
    const districtData = async (stateid) => {
        try {
            await axios.get(Constants.baseUrl + `master/geography/state/${stateid}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            setState(res.data.output.name)
                            setStateId(res.data.output.id)
                            setDistrictFetch(res.data.output.districts)
                        } else {
                            // swal({
                            //     text: res.data.msg,
                            //     timer: 2000,
                            //     buttons: false,
                            // });
                        }
                    } else {
                        // swal({
                        //     text: res.data.msg,
                        //     timer: 2000,
                        //     buttons: false,
                        // });
                    }
                })

        } catch (error) {
            console.log(error)
        }
    }

    // call district api by district id for get city name
    const handleDistrictChange = async (event) => {
        const selectedId = event.target.value
        setCityFetch([])
        setCity('')
        setPincode('')
        if (selectedId === '0') {
            setDistrict('')
            setCity('')
        } else {
            cityData(selectedId);
        }
    };

    const cityData = async (cityid) => {
        try {
            await axios.get(Constants.baseUrl + `master/geography/district/${cityid}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            setDistrict(res.data.output.name)
                            setDistrictId(res.data.output.id)
                            setCityFetch(res.data.output.cities)

                        } else {
                            // swal({
                            //     text: res.data.msg,
                            //     timer: 2000,
                            //     buttons: false,
                            // });
                        }
                    } else {
                        // swal({
                        //     text: res.data.msg,
                        //     timer: 2000,
                        //     buttons: false,
                        // });
                    }
                })

        } catch (error) {
            console.log(error)
        }
    }


    const handlePhoto = (event) => {
        updatePhoto(event.target.files[0]);
    };
    const handleAadharFront = (event) => {
        updateAdhaarFront(event.target.files[0]);
    };
    const handleAadharBack = (event) => {
        updateAdhaarBack(event.target.files[0]);
    };
    const handleCheque = (event) => {
        updateCheque(event.target.files[0]);
    }
    const handlePanCard = (event) => {
        updatePan(event.target.files[0]);
    };
    const handleUpiIdScreen = (event) => {
        updateUpiIdScreen(event.target.files[0]);
    }

    const updatePhoto = (file) => {
        let token = localStorage.getItem('token');
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', 'photo');
            axios.post(Constants.baseUrl + 'uploader/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Accept-Language': localStorage.getItem('language'),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    company: Constants.company,
                    program: Constants.program,
                },
            }).then((res) => {
                if (res.status === 201) {
                    setPhoto(res.data.output.url)
                } else {
                    swal('Something went wrong!');
                }
            });
        } catch (error) {
            swal(error)
        }
    }
    const updateAdhaarFront = (file) => {
        let token = localStorage.getItem('token');
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', 'aadhar');
            axios.post(Constants.baseUrl + 'uploader/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Accept-Language': localStorage.getItem('language'),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    company: Constants.company,
                    program: Constants.program,
                },
            }).then((res) => {
                if (res.status === 201) {
                    setAadharFrontUrl(res.data.output.url)
                } else {
                    swal('Something went wrong!');
                }
            });
        } catch (error) {
            swal(error)
        }
    }
    const updateAdhaarBack = (file) => {
        let token = localStorage.getItem('token');
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', 'aadhar');

            axios.post(Constants.baseUrl + 'uploader/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Accept-Language': localStorage.getItem('language'),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    company: Constants.company,
                    program: Constants.program,
                },
            }).then((res) => {
                if (res.status === 201) {
                    setAadharBackUrl(res.data.output.url)

                } else {
                    // swal('Something went wrong!');
                }
            });
        } catch (error) {
            swal(error)
        }
    }
    const updatePan = (file) => {
        let token = localStorage.getItem('token');

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', 'pan');

            axios.post(Constants.baseUrl + 'uploader/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Accept-Language': localStorage.getItem('language'),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    company: Constants.company,
                    program: Constants.program,
                },
            }).then((res) => {
                if (res.status === 201) {
                    setPanFrontUrl(res.data.output.url)

                } else {
                    // swal('Something went wrong!');
                }
            });
        } catch (error) {
            swal(error)
        }
    }
    const updateCheque = (file) => {
        let token = localStorage.getItem('token');

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', 'cheque');

            axios.post(Constants.baseUrl + 'uploader/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Accept-Language': localStorage.getItem('language'),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    company: Constants.company,
                    program: Constants.program,
                },
            }).then((res) => {
                if (res.status === 201) {
                    setCancelChk(res.data.output.url)

                } else {
                    // swal('Something went wrong!');
                }
            });
        } catch (error) {
            swal(error)
        }
    }
    const updateUpiIdScreen = (file) => {
        let token = localStorage.getItem('token');

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', 'upiId');

            axios.post(Constants.baseUrl + 'uploader/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Accept-Language': localStorage.getItem('language'),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    company: Constants.company,
                    program: Constants.program,
                },
            }).then((res) => {
                if (res.status === 201) {
                    setUpiIdScreen(res.data.output.url)
                } else {
                    // swal('Something went wrong!');
                }
            });
        } catch (error) {
            swal(error)
        }
    }


    // registration form validation
    const validateForm = () => {

        let errors = {};

        if (!name) {
            errors.name = 'Name required';
        } else if (name.length < 3) {
            errors.name = 'Name must be at least 3 characters';
        }
        if (!mobile) {
            errors.mobile = 'Mobile no. required';
        } else if (mobile.length !== 10) {
            errors.mobile = 'Mobile no. must be 10 digits';
        } else if (!/^[6-9]\d{9}$/.test(mobile)) {
            errors.mobile = 'Invalid mobile no.';
        }
        // if (!email) {
        //     errors.email = 'Email ID required';
        // } else if (!/\S+@\S+\.\S+/.test(email)) {
        //     errors.email = 'Invalid email address';
        // }

        if (alternateMobile) {
            if (alternateMobile.length !== 10) {
                errors.alternateMobile = 'Alt. mobile no. must be 10 digits';
            } else if (!/^[6-9]\d{9}$/.test(alternateMobile)) {
                errors.alternateMobile = 'Invalid mobile no.';
            }
        }
        if (whatsappNumber) {
            if (whatsappNumber.length !== 10) {
                errors.whatsappNumber = 'Whatsapp no. must be 10 digits';
            } else if (!/^[6-9]\d{9}$/.test(whatsappNumber)) {
                errors.whatsappNumber = 'Invalid mobile no.';
            }
        }
        if (!state) {
            errors.state = 'State required'
        }
        if (!district) {
            errors.district = 'District required'
        }
        if (!city) {
            errors.city = 'City required'
        }
        if (!pincode) {
            errors.pincode = ' Pincode required';
        } else if (!/^\d+$/.test(pincode)) {
            errors.pincode = 'Pincode must be numeric';
        } else if (pincode.length !== 6) {
            errors.pincode = 'Pincode must be 6 digits';
        }
        if (!gender) {
            errors.gender = 'Gender required'
        }
        if (!dob) {
            errors.dob = 'DOB required'
        }
        // if (photo) {
        //     errors.photo = 'Please select a image'
        // }
        if (!aadharNumber) {
            errors.aadharNumber = 'Aadhar no. required';
        } else if (!/^\d+$/.test(aadharNumber)) {
            errors.aadharNumber = 'aadhar no. must be numeric';
        } else if (aadharNumber.length !== 12) {
            errors.aadharNumber = 'aadhar no. must be 12 digits';
        }
        if (!aadharFrontUrl) {
            errors.aadharFrontUrl = 'Please select Image'
        }
        if (!aadharBackUrl) {
            errors.aadharBackUrl = 'Please select Image'
        }
        if (panNumber) {
            if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(panNumber)) {
                errors.panNumber = 'Invalid PAN format';
            }
        }
        // if (panFrontUrl) {
        //     errors.panFrontUrl = 'Please select Image'
        // }
        if (bankDetailShow === 'bankdetails') {
            if (!accountNo) {
                errors.accountNo = 'Account no. required';
            } else if (!/^\d+$/.test(accountNo)) {
                errors.accountNo = 'Account no. must be numeric';
            } else if (accountNo.length < 5) {
                errors.accountNo = 'Account no. minimum five digits';
            }
            if (!holderName) {
                errors.holderName = 'Account holder name required';
            } else if (holderName.length < 3) {
                errors.holderName = 'Name must be at least 3 characters';
            }
            if (!accountType) {
                errors.accountType = 'Account type required';
            }
            if (!bankName) {
                errors.bankName = 'Bank name required';
            }
            if (!ifsc) {
                errors.ifsc = 'IFSC code required';
            } else if (ifsc.length !== 11) {
                errors.ifsc = 'IFSC code must be 11 digits';
            } else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{11}$/.test(ifsc)) {
                errors.ifsc = 'IFSC code must contain a mix of letters and numbers';
            }
            if (!cancelChk) {
                errors.cancelChk = 'Please select Image';
            }
        } else if (bankDetailShow === 'upidetails') {
            if (!upiId) {
                errors.upiId = 'UPI id required';
            } else if (!upiId.includes('@')) {
                errors.upiId = 'Invalid upi id';
            }
            if (!upiIdScreen) {
                errors.upiIdScreen = 'Please select Image';
            }
        }

        return errors;
    };

    //call register api for plumber registration
    const register = async (event) => {
        let token = localStorage.getItem('token');
        event.preventDefault();
        setErrors({});
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        }
        try {
            await axios.post(Constants.baseUrl + "user/manage", {
                name: name,
                email: '',
                mobile: mobile,
                alternateMobile: alternateMobile,
                mobileWhatsApp: whatsappNumber,
                dob: formattedDob,
                gender: gender,
                password: '',
                status: 'NEW',
                userModelKYC: {
                    aadharMobile: '',
                    aadharNumber: aadharNumber,
                    aadharFrontUrl: aadharFrontUrl,
                    aadharBackUrl: aadharBackUrl,
                    aadharVerify: 0,
                    aadharVerifiedBy: '',
                    aadharVerifiedOn: '2023-07-24T04:29:21.100Z',
                    panNumber: panNumber,
                    panFrontUrl: panFrontUrl,
                    panVerify: 0,
                    panVerifiedBy: '',
                    panVerifiedOn: '2023-07-24T04:29:21.100Z'
                },
                addressWork: {
                    workAddress1: '',
                    workAddress2: '',
                    workAddress3: '',
                    workCity: city,
                    workCityId: cityId,
                    workState: state,
                    workStateId: stateId,
                    workdistrict: district,
                    workdistrictId: districtId,
                    workPincode: pincode,
                    workCountry: ''
                },
                bankDetail: {
                    bankAccountNo: accountNo,
                    accountType: accountType,
                    ifsc: ifsc,
                    beneficiary: '',
                    bankName: bankName,
                    holderName: holderName,
                    branchName: '',
                    cancelChk: cancelChk,
                    upiId: upiId,
                    upiIdScreen: upiIdScreen
                },
                parentId: 0,
                parentType: '',
                userType: 'MEMBER',
                identityType: '',
                identityNumber: '',
                identityCardBackUrl: '',
                identityCardFrontUrl: '',
                getsStateId: '',
                getsDistrictId: '',
                getsCityId: ''
            }, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program
                },
            })
                .then((res) => {
                    if (res.status === 201) {
                        if (res.data.code === 10001) {
                            setMobileOtp('')
                            swal({
                                text: "Registration Successfull",
                                // text: "Please Enter Otp Sent on Plumber's Mobile",
                                icon: 'success',
                                timer: 2000,
                                buttons: false,
                            });
                            // setTimeout(() => {
                            //     setMobileModalShow(true)
                            // }, 2000);
                            setTimeout(() => {
                                // CancelRegisteration();
                                window.location = '/registration'
                            }, 2000);
                        } else if (res.data.code === 12001) {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            });
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            });
                        }

                    } else {
                        swal(res.data.msg)
                    }
                });
        } catch (error) {
            console.log(error)
        }

    }

    const validateMobileOtp = () => {

        let errors = {};

        if (!mobileOtp) {
            errors.mobileOtp = 'Please Enter OTP';
        } else if (mobileOtp.length !== 6) {
            errors.mobileOtp = 'OTP must be 6 digit'
        }
        return errors;
    };
    const mobileVerifyOtp = async () => {
        let token = localStorage.getItem('token');

        setErrors({});

        const validationErrors = validateMobileOtp();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        }
        try {
            await axios.post(Constants.baseUrl + "auth/verify-otp/newSales", {
                otp: mobileOtp,
                mobile: mobile
            }, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            swal({
                                text: res.data.msg,
                                icon: 'success',
                                buttons: false,
                                timer: 2000,
                            });
                            setMobileModalShow(false)
                            setTimeout(() => {
                                // CancelRegisteration();
                                window.location = '/registration'
                            }, 2000);
                        } else if (res.data.code === 12001) {
                            setMobileOtp('')
                            swal({
                                text: res.data.msg,
                                // icon: 'error',
                                timer: 2000,
                                buttons: false,
                            });
                        } else {
                            swal({
                                text: res.data.msg,
                                // icon: 'error',
                                timer: 2000,
                                buttons: false,
                            });
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        });
                    }
                });
        } catch (error) {
            console.log(error)
        }
    }

    const CancelRegisteration = () => {
        window.location = '/registration'
        setName('');
        setMobile('')
        setAlternateMobile('')
        setAlternateMobile('')
        setWhatsappNumber('')
        setPincode('');
        setState('');
        setStateId('')
        setDistrict('');
        setDistrict('')
        setCity('');
        setCity('')
        setGender('')
        setDob('')
        setPhoto('')
        setAadharNumber('')
        setAadharFrontUrl('')
        setAadharBackUrl('')
        setPanNumber('')
        setPanFrontUrl('')
        setAccountNo('');
        setHolderName('');
        setAccountType('');
        setBankName('');
        setIfsc('');
        setCancelChk('');
        setUpiId('');
        setUpiIdScreen('')
    }
    const handleYearDropdownClick = () => {
        setShowUpArrow(!showUpArrow); // Toggle the state to show/hide the up arrow
    };
    return (
        <>
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Plumber Details</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt m-b-1">
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>Name* </label>
                                            <input className="form-control" maxLength="35" placeholder="Enter your name" type="text" value={name} onChange={(event) => {
                                                const input = event.target.value;
                                                if (/^[a-zA-Z\s]*$/.test(input)) {  // Only alphabetic characters
                                                    setName(input);
                                                }
                                            }} />
                                        </div>
                                        {errors.name && (
                                            <p className="error-message">{errors.name}</p>
                                        )}
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>Mobile No. *</label>
                                            <input
                                                className="form-control"
                                                placeholder="Enter Mobile Number"
                                                maxLength="10"
                                                type="text"
                                                value={mobile}
                                                onChange={(event) => {
                                                    const input = event.target.value;
                                                    if (/^\d*$/.test(input)) {
                                                        setMobile(input);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {errors.mobile && (
                                            <div className="error-message">{errors.mobile}</div>
                                        )}
                                    </div>
                                    {/* <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>Email ID*</label>
                                            <input className="form-control" placeholder="Enter mail ID" type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                                        </div>
                                        {errors.email && (
                                            <div className="error-message">{errors.email}</div>
                                        )}
                                    </div> */}
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Alternate Mobile Number</label>
                                            <input
                                                className="form-control"
                                                placeholder="Enter Mobile Number"
                                                maxLength="10"
                                                type="text"
                                                value={alternateMobile}
                                                onChange={(event) => {
                                                    const input = event.target.value;
                                                    if (/^\d*$/.test(input)) {
                                                        setAlternateMobile(input);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {errors.alternateMobile && (
                                            <div className="error-message">{errors.alternateMobile}</div>
                                        )}
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label> Whatsapp Number</label>
                                            <input
                                                className="form-control"
                                                placeholder="Enter Mobile Number"
                                                maxLength="10"
                                                type="text"
                                                value={whatsappNumber}
                                                onChange={(event) => {
                                                    const input = event.target.value;
                                                    if (/^\d*$/.test(input)) {
                                                        setWhatsappNumber(input);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {errors.whatsappNumber && (
                                            <div className="error-message">{errors.whatsappNumber}</div>
                                        )}
                                    </div>


                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>State*</label>
                                            <select className="form-control2" onChange={handleStateChange}>
                                                <option value='0'>Select State</option>
                                                {stateFetch.map((item, index) => {
                                                    return (
                                                        <>
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        </>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {errors.state && (
                                            <div className="error-message">{errors.state}</div>
                                        )}
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>District*</label>
                                            <select className="form-control2" onChange={handleDistrictChange}>
                                                <option value='0'>Select District</option>
                                                {districtFetch.map((item, index) => {
                                                    return (
                                                        <>
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        </>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {errors.district && (
                                            <div className="error-message">{errors.district}</div>
                                        )}
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>City*</label>
                                            <select className="form-control2" value={city} onChange={(event) => {
                                                setCity(event.target.value);
                                                // Step 2: Find and set the selected city's ID
                                                const selectedCity = cityFetch.find((item) => item.name === event.target.value);
                                                setCityId(selectedCity ? selectedCity.id : '');
                                            }}>
                                                <option value=''>Select City</option>
                                                {cityFetch.map((item, index) => {
                                                    return (
                                                        <>
                                                            <option key={index} value={item.name}>{item.name}</option>
                                                        </>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {errors.city && (
                                            <div className="error-message">{errors.city}</div>
                                        )}
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>Pin Code*</label>
                                            <input className="form-control" placeholder="Pincode" type="text" maxLength='6' value={pincode} onChange={(event) => {
                                                const input = event.target.value;
                                                if (/^\d*$/.test(input)) {
                                                    setPincode(input);
                                                }
                                            }} />
                                        </div>
                                        {errors.pincode && (
                                            <div className="error-message">{errors.pincode}</div>
                                        )}
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label>Gender*</label>
                                            <select className="form-control2" value={gender} onChange={(e) => setGender(e.target.value)}>
                                                <option value="">Select </option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        {errors.gender && (
                                            <div className="error-message">{errors.gender}</div>
                                        )}
                                    </div>
                                    <div className={`col-md-3 col-sm-12 col-xs-12 ${showUpArrow ? 'up-arrow' : ''}`}>
                                        <div className="form-group has-float-label style-two" onClick={handleYearDropdownClick} >
                                            <label className='dob-100'>D.O.B*</label>
                                            <DatePicker
                                                selected={dob}
                                                onChange={(date) => setDob(date)}
                                                dateFormat="dd-MM-yyyy "
                                                className="form-control"
                                                value={dob}
                                                showYearDropdown
                                                showMonthDropdown
                                                placeholderText="dd-mm-yyyy"
                                                maxDate={exactMinDate} // Up to the exact 18th birthday
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                            />
                                        </div>
                                        {errors.dob && (
                                            <div className="error-message">{errors.dob}</div>
                                        )}
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Selfie</label>
                                            <img className="myimage" id="myimage1" src="" />
                                            <div className="upload-btn-wrapper">
                                                <button className="btn">Upload a file</button>
                                                <input type="file" id="file-input1" />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>Selfie</label>
                                            {/* {photo !== '' ? <Link to={photo} target="_blank"><span><i className="fa fa-eye">View</i></span></Link> : ''} */}
                                            <input className="form-control" id="" name="" accept=".jpg, .jpeg, .png, .svg" type="file" onChange={handlePhoto} />
                                        </div>
                                        {errors.photo && (
                                            <div className="error-message">{errors.photo}</div>
                                        )}
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>12 Digit Aadhaar No.*</label>
                                            <input autoComplete="nope" className="form-control" maxLength="12" minLength="12" name="AadharNo" placeholder="Aadhar No." type="text" value={aadharNumber} onChange={(event) => {
                                                const input = event.target.value;
                                                if (/^\d*$/.test(input)) {
                                                    setAadharNumber(input);
                                                }
                                            }} />
                                        </div>
                                        {errors.aadharNumber && (
                                            <div className="error-message">{errors.aadharNumber}</div>
                                        )}
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Aadhaar Front Image*</label>
                                            {/* <img className="myimage" id="myimage2" src="" alt='' /> */}
                                            <input className="form-control" id="" name="" accept=".jpg, .jpeg, .png, .svg" type="file" onChange={handleAadharFront} />
                                        </div>
                                        {errors.aadharFrontUrl && (
                                            <div className="error-message">{errors.aadharBackUrl}</div>
                                        )}
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Aadhaar Back Image*</label>
                                            <img className="myimage" id="myimage3" src="" alt='' />
                                            <input className="form-control" id="" name="" accept=".jpg, .jpeg, .png, .svg" type="file" onChange={handleAadharBack} />
                                        </div>
                                        {errors.aadharBackUrl && (
                                            <div className="error-message">{errors.aadharBackUrl}</div>
                                        )}
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label> PAN No.</label>
                                            <input autoComplete="nope" className="form-control" id="" maxLength="10" minLength="10" name="" placeholder="PAN No." type="text" value={panNumber} onChange={(event) => setPanNumber(event.target.value.toUpperCase())} />
                                        </div>
                                        {errors.panNumber && (
                                            <div className="error-message">{errors.panNumber}</div>
                                        )}
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>PAN Image</label>
                                            <input className="form-control" id="" name="" accept=".jpg, .jpeg, .png, .svg" type="file" onChange={handlePanCard} />
                                        </div>
                                        {errors.panFrontUrl && (
                                            <div className="error-message">{errors.panFrontUrl}</div>
                                        )}
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <div className="h34">
                                                <label className="radio-inline">
                                                    <input id="chckbanking" name="PaymentMode" type="checkBox" checked={bankDetailShow === 'bankdetails'} value='bankdetails' onChange={(event) => {
                                                        if (event.target.checked) {
                                                            setBankDetailShow('bankdetails');
                                                            setUpiId('')
                                                            setUpiIdScreen('')

                                                        } else {
                                                            setBankDetailShow(null);
                                                            setHolderName('')
                                                            setAccountType('')
                                                            setBankName('')
                                                            setAccountNo('')
                                                            setIfsc('')
                                                            setCancelChk('')
                                                        }
                                                    }} />
                                                    <span> Bank Details</span>
                                                </label>
                                                <label className="radio-inline">
                                                    <input id="chckupi" name="PaymentMode" type="checkBox" checked={bankDetailShow === 'upidetails'} value='upidetails' onChange={(event) => {
                                                        if (event.target.checked) {
                                                            setBankDetailShow('upidetails');
                                                            setHolderName('')
                                                            setAccountType('')
                                                            setBankName('')
                                                            setAccountNo('')
                                                            setIfsc('')
                                                            setCancelChk('')
                                                        } else {
                                                            setBankDetailShow(null);
                                                            setUpiId('')
                                                        }
                                                    }} /> <span> UPI Details</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {bankDetailShow === 'bankdetails' ?
                                        <div className="bankdetails" id="bankdetails">
                                            <div className="col-md-12">
                                                <h4 className="heading-panel">Bank Details</h4>
                                            </div>
                                            <div className="col-md-3 col-sm-12 col-xs-12">
                                                <div className="form-group has-float-label style-two">
                                                    <label>Account Number*</label>
                                                    <input autoComplete="nope" className="form-control" data-val="true" data-val-required="Account Number Required" id="txtacntno" maxLength="20" name="AccountNumber" placeholder="Account Number" type="text" value={accountNo} onChange={(event) => {
                                                        const input = event.target.value;
                                                        if (/^\d*$/.test(input)) {
                                                            setAccountNo(input);
                                                        }
                                                    }} />
                                                </div>
                                                {errors.accountNo && (
                                                    <div className="error-message">{errors.accountNo}</div>
                                                )}
                                            </div>
                                            <div className="col-md-3 col-sm-12 col-xs-12">
                                                <div className="form-group has-float-label style-two">
                                                    <label>Account Holder Name*</label>
                                                    <input autoComplete="nope" className="form-control" maxLength="35" placeholder="Account Holder Name" type="text" value={holderName} onChange={(event) => {
                                                        const input = event.target.value;
                                                        if (/^[a-zA-Z\s]*$/.test(input)) {  // Only alphabetic characters
                                                            setHolderName(input);
                                                        }
                                                    }} />
                                                </div>
                                                {errors.holderName && (
                                                    <div className="error-message">{errors.holderName}</div>
                                                )}
                                            </div>
                                            <div className="col-md-3 col-sm-12 col-xs-12">
                                                <div className="form-group has-float-label style-two">
                                                    <label>Account Type*</label>
                                                    <select className="form-control2" data-val="true" data-val-required="Account Type Required" id="" style={{ alignItem: 'center' }} value={accountType} onChange={(event) => setAccountType(event.target.value)} >
                                                        <option value="">Select Account Type</option>
                                                        <option value="Current account">Current account</option>
                                                        <option value="Savings account">Savings account</option>
                                                        <option value="Salary account">Salary account</option>
                                                    </select>
                                                </div>
                                                {errors.accountType && (
                                                    <div className="error-message">{errors.accountType}</div>
                                                )}
                                            </div>
                                            <div className="col-md-3 col-sm-12 col-xs-12">
                                                <div className="form-group has-float-label style-two">
                                                    <label>Bank Name*</label>
                                                    <select autoComplete="off" className="form-control2" data-val="true" data-val-required="Bank Name Required" id="BankName" name="BankName" value={bankName} onChange={(event) => setBankName(event.target.value)}>
                                                        <option selected="selected" value="">Select Bank Name</option>
                                                        <option value="Bank of Baroda">Bank of Baroda</option>
                                                        <option value="Bank of India">Bank of India</option>
                                                        <option value="Bank of Maharashtra">Bank of Maharashtra</option>
                                                        <option value="Canara Bank">Canara Bank</option>
                                                        <option value="Central Bank of India">Central Bank of India</option>
                                                        <option value="Indian Bank">Indian Bank</option>
                                                        <option value="Indian Overseas Bank">Indian Overseas Bank</option>
                                                        <option value="Punjab &amp; Sind Bank">Punjab &amp; Sind Bank</option>
                                                        <option value="Punjab National Bank">Punjab National Bank</option>
                                                        <option value="State Bank of India">State Bank of India</option>
                                                        <option value="UCO Bank">UCO Bank</option>
                                                        <option value="Union Bank of India">Union Bank of India</option>
                                                        <option value="Axis Bank">Axis Bank</option>
                                                        <option value="Bandhan Bank">Bandhan Bank</option>
                                                        <option value="CSB Bank">CSB Bank</option>
                                                        <option value="City Union Bank">City Union Bank</option>
                                                        <option value="DCB Bank">DCB Bank</option>
                                                        <option value="Dhanlaxmi Bank">Dhanlaxmi Bank</option>
                                                        <option value="Federal Bank">Federal Bank</option>
                                                        <option value="HDFC Bank">HDFC Bank</option>
                                                        <option value="ICICI Bank">ICICI Bank</option>
                                                        <option value="Induslnd Bank">Induslnd Bank</option>
                                                        <option value="IDFC First Bank">IDFC First Bank</option>
                                                        <option value="Jammu &amp; Kashmir Bank">Jammu &amp; Kashmir Bank</option>
                                                        <option value="Karnataka Bank">Karnataka Bank</option>
                                                        <option value="Karur Vysya Bank">Karur Vysya Bank</option>
                                                        <option value="Kotak Mahindra Bank">Kotak Mahindra Bank</option>
                                                        <option value="Lakshmi Vilas Bank">Lakshmi Vilas Bank</option>
                                                        <option value="Nainital Bank">Nainital Bank</option>
                                                        <option value="RBL Bank">RBL Bank</option>
                                                        <option value="South Indian Bank">South Indian Bank</option>
                                                        <option value="Tamilnad Mercantile Bank">Tamilnad Mercantile Bank</option>
                                                        <option value="YES Bank">YES Bank</option>
                                                        <option value="IDBI Bank">IDBI Bank</option>
                                                        <option value="Au Small Finance Bank">Au Small Finance Bank</option>
                                                        <option value="Capital Small Finance Bank">Capital Small Finance Bank</option>
                                                        <option value="Equitas Small Finance Bank">Equitas Small Finance Bank</option>
                                                        <option value="Suryoday Small Finance Bank">Suryoday Small Finance Bank</option>
                                                        <option value="Ujjivan Small Finance Bank">Ujjivan Small Finance Bank</option>
                                                        <option value="Utkarsh Small Finance Bank">Utkarsh Small Finance Bank</option>
                                                        <option value="ESAF Small Finance Bank">ESAF Small Finance Bank</option>
                                                        <option value="Fincare Small Finance Bank">Fincare Small Finance Bank</option>
                                                        <option value="Jana Small Finance Bank">Jana Small Finance Bank</option>
                                                        <option value="North East Small Finance Bank">North East Small Finance Bank</option>
                                                        <option value="Shivalik Small Finance Bank">Shivalik Small Finance Bank</option>
                                                        <option value="Unity Small Finance Bank">Unity Small Finance Bank</option>
                                                        <option value="India Post Payments Bank">India Post Payments Bank</option>
                                                        <option value="Fino Payments Bank">Fino Payments Bank</option>
                                                        <option value="Paytm Payments Bank">Paytm Payments Bank</option>
                                                        <option value="Airtel Payments Bank">Airtel Payments Bank</option>
                                                        <option value="Andhra Pragathi Grameena Bank">Andhra Pragathi Grameena Bank</option>
                                                        <option value="Andhra Pradesh Grameena Vikas Bank">Andhra Pradesh Grameena Vikas Bank</option>
                                                        <option value="Arunachal Pradesh Rural Bank">Arunachal Pradesh Rural Bank</option>
                                                        <option value="Aryavart Bank">Aryavart Bank</option>
                                                        <option value="Assam Gramin Vikash Bank">Assam Gramin Vikash Bank</option>
                                                        <option value="Bangiya Gramin Vikas Bank">Bangiya Gramin Vikas Bank</option>
                                                        <option value="Baroda Gujarat Gramin Bank">Baroda Gujarat Gramin Bank</option>
                                                        <option value="Baroda Rajasthan Kshetriya Gramin Bank">Baroda Rajasthan Kshetriya Gramin Bank</option>
                                                        <option value="Baroda UP Bank">Baroda UP Bank</option>
                                                        <option value="Chaitanya Godavari Grameena Bank">Chaitanya Godavari Grameena Bank</option>
                                                        <option value="Chhattisgarh Rajya Gramin Bank">Chhattisgarh Rajya Gramin Bank</option>
                                                        <option value="Dakshin Bihar Gramin Bank">Dakshin Bihar Gramin Bank</option>
                                                        <option value="Ellaquai Dehati Bank">Ellaquai Dehati Bank</option>
                                                        <option value="Himachal Pradesh Gramin Bank">Himachal Pradesh Gramin Bank</option>
                                                        <option value="J&amp;K Grameen Bank">J&amp;K Grameen Bank</option>
                                                        <option value="Jharkhand Rajya Gramin Bank">Jharkhand Rajya Gramin Bank</option>
                                                        <option value="Karnataka Gramin Bank">Karnataka Gramin Bank</option>
                                                        <option value="Karnataka Vikas Grameena Bank">Karnataka Vikas Grameena Bank</option>
                                                        <option value="Kerala Gramin Bank">Kerala Gramin Bank</option>
                                                        <option value="Madhya Pradesh Gramin Bank">Madhya Pradesh Gramin Bank</option>
                                                        <option value="Madhyanchal Gramin Bank">Madhyanchal Gramin Bank</option>
                                                        <option value="Maharashtra Gramin Bank">Maharashtra Gramin Bank</option>
                                                        <option value="Manipur Rural Bank">Manipur Rural Bank</option>
                                                        <option value="Meghalaya Rural Bank">Meghalaya Rural Bank</option>
                                                        <option value="Mizoram Rural Bank">Mizoram Rural Bank</option>
                                                        <option value="Nagaland Rural Bank">Nagaland Rural Bank</option>
                                                        <option value="Odisha Gramya Bank">Odisha Gramya Bank</option>
                                                        <option value="Paschim Banga Gramin Bank">Paschim Banga Gramin Bank</option>
                                                        <option value="Prathama UP Gramin Bank">Prathama UP Gramin Bank</option>
                                                        <option value="Puduvai Bharathiar Grama Bank">Puduvai Bharathiar Grama Bank</option>
                                                        <option value="Punjab Gramin Bank">Punjab Gramin Bank</option>
                                                        <option value="Rajasthan Marudhara Gramin Bank">Rajasthan Marudhara Gramin Bank</option>
                                                        <option value="Saptagiri Grameena Bank">Saptagiri Grameena Bank</option>
                                                        <option value="Sarva Haryana Gramin Bank">Sarva Haryana Gramin Bank</option>
                                                        <option value="Saurashtra Gramin Bank">Saurashtra Gramin Bank</option>
                                                        <option value="Tamil Nadu Grama Bank">Tamil Nadu Grama Bank</option>
                                                        <option value="Telangana Grameena Bank">Telangana Grameena Bank</option>
                                                        <option value="Tripura Gramin Bank">Tripura Gramin Bank</option>
                                                        <option value="Utkal Grameen bank">Utkal Grameen bank</option>
                                                        <option value="Uttar Bihar Gramin Bank">Uttar Bihar Gramin Bank</option>
                                                        <option value="Uttarakhand Gramin Bank">Uttarakhand Gramin Bank</option>
                                                        <option value="Uttarbanga Kshetriya Gramin Bank">Uttarbanga Kshetriya Gramin Bank</option>
                                                        <option value="Vidharbha Konkan Gramin Bank">Vidharbha Konkan Gramin Bank</option>
                                                    </select>
                                                    {/* <span className="field-validation-valid errormsgr" data-valmsg-for="BankName" data-valmsg-replace="true" style={{ color: 'red' }}></span> */}
                                                </div>
                                                {errors.bankName && (
                                                    <div className="error-message">{errors.bankName}</div>
                                                )}
                                            </div>
                                            <div className="col-md-3 col-sm-12 col-xs-12">
                                                <div className="form-group has-float-label style-two">
                                                    <label>IFSC Code*</label>
                                                    <input autoComplete="nope" className="form-control" maxLength="11" minLength="11" placeholder="IFSC Code" type="text" value={ifsc} onChange={(event) => setIfsc(event.target.value.toUpperCase())} />
                                                </div>
                                                {errors.ifsc && (
                                                    <div className="error-message">{errors.ifsc}</div>
                                                )}
                                            </div>
                                            <div className="col-md-3 col-sm-12 col-xs-12">
                                                <div className="form-group has-float-label style-two">
                                                    <label>Passbook/Cancelled Cheque*</label>
                                                    <input className="form-control" id="" name="" type="file" accept=".jpg, .jpeg, .png, .svg" onChange={handleCheque} />
                                                </div>
                                                {errors.cancelChk && (
                                                    <div className="error-message">{errors.cancelChk}</div>
                                                )}
                                            </div>
                                        </div> : ''}
                                    {bankDetailShow === 'upidetails' ?
                                        <div id="upidetails">
                                            <div className="col-md-12">
                                                <h4 className="heading-panel">UPI Transfer</h4>
                                            </div>
                                            <div className="col-md-3 col-sm-12 col-xs-12">
                                                <div className="form-group has-float-label style-two">
                                                    <label>Enter your UPI ID*</label>
                                                    <input autoComplete="nope" className="form-control" placeholder="UPI ID" type="text" value={upiId} onChange={(event) => setUpiId(event.target.value)} />
                                                </div>
                                                {errors.upiId && (
                                                    <div className="error-message">{errors.upiId}</div>
                                                )}
                                            </div>
                                            <div className="col-md-3 col-sm-12 col-xs-12">
                                                <div className="form-group has-float-label style-two">
                                                    <label>UPI ID Screenshot*</label>
                                                    <input className="form-control" id="" type="file" accept=".jpg, .jpeg, .png, .svg" onChange={handleUpiIdScreen} />
                                                </div>
                                                {errors.upiIdScreen && (
                                                    <div className="error-message">{errors.upiIdScreen}</div>
                                                )}
                                            </div>
                                        </div> : ''}
                                    <div className="col-md-12">
                                        <div className="form-group text-center mt25">
                                            <input type="submit" value="Submit" className="btn login_btn" onClick={register} />

                                            <input type="submit" value="Cancel" className="btn resetbtn" onClick={CancelRegisteration} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Modal
                isOpen={mobileModalShow}
                // onAfterOpen={afterOpenModal}
                onRequestClose={() => setMobileModalShow(false)}
                style={customStyles}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
            >
                <div className="otp-modal">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" onClick={() => setMobileModalShow(false)}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-details">
                                    <div className="row">
                                        <div className="box-feild">
                                            <OtpInput
                                                value={mobileOtp}
                                                onChange={setMobileOtp}
                                                numInputs={6}
                                                // renderSeparator={<span>-</span>}
                                                renderInput={(props) => <input {...props} />}
                                            />
                                        </div>
                                        {errors.mobileOtp && (
                                            <div className="error-message-modal">{errors.mobileOtp}</div>
                                        )}
                                        <div className="form-group col-lg-12 col-md-12 text-center btn-login">
                                            <input type="submit" className="btn login_btn" value="Verify" onClick={mobileVerifyOtp} />
                                            {/* <input type="submit" className="btn btn-reject" value="Resend OTP" onClick={mobileResendOtp} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Footer />
        </>
    )
}
