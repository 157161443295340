import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
export default function Oriya() {
    return (
        <section className="blog-area pt182">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 tab mb-20">
                        <ul className="nav nav-tabs" role="tablist">
                            {/* <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">FAQ's</Link></li> */}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="all-single-blog rpt">
                            <div className="carrd1 requestbox">
                                <div className="card-body pad30">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <Accordion activeIndex={0}>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.1 ମୁଁ ପ୍ରୋଗ୍ରାମ୍ ପାଇଁ କିପରି ପଞ୍ଜୀକରଣ କରିବି?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ଗୁଗଲ ପ୍ଲେ ଷ୍ଟୋର କିମ୍ବା ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ସେଲ୍ସ ଟିମ୍ ପାଖରୁ ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ସୁପର ପ୍ଲମ୍ବର ଲୟାଲିଟି ପ୍ରୋଗ୍ରାମ ଆପ୍ ଡାଉନଲୋଡ୍ କରନ୍ତୁ, ସେଲ୍ସ ଟିମ୍ ମଧ୍ୟ ୱେବ୍ ଆପ୍ ମାଧ୍ୟମରେ ସେମାନଙ୍କ ଲଗଇନ୍ ବ୍ୟବହାର କରି ଆପଣଙ୍କୁ ପଞ୍ଜୀକରଣ କରିପାରିବେ|
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.2 ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ସୁପର ପ୍ଲମ୍ବର ଲୟାଲିଟି ପ୍ରୋଗ୍ରାମରେ ଭାଗ ନେବାକୁ କିଏ ଯୋଗ୍ୟ?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ବୈଧ ଆଧାର କାର୍ଡ ଥିବା ଏବଂ 18 ବର୍ଷରୁ ଅଧିକ ବୟସର ଯେକୌଣସି ପ୍ଲମ୍ବର। ଯେଉଁମାନେ ଏକ ଅଧିକୃତ ରିଟେଲରଙ୍କ ଠାରୁ ଫିନୋଲେକ୍ସ ପାଇପ୍‌ର ଉତ୍ପାଦ କ୍ରୟ କରନ୍ତି ସେମାନେ ଏହି କାର୍ଯ୍ୟକ୍ରମରେ ଅଂଶଗ୍ରହଣ କରିବାକୁ ଯୋଗ୍ୟ ଅଟନ୍ତି|
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.3 ମୁଁ କିପରି ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ସୁପର ପ୍ଲମ୍ବର ଲୟାଲିଟି ପ୍ରୋଗ୍ରାମ ଡାଉନଲୋଡ୍ କରିବି?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ଆଣ୍ଡ୍ରଏଡ୍ ଉପଭୋକ୍ତାମାନେ କେବଳ ଗୁଗଲ୍ ପ୍ଲେ ଷ୍ଟୋରକୁ ଯାଇ ଫିନୋଲେକ୍ସ ପାଇପ୍ ସୁପର ପ୍ଲମ୍ବର ଲୟାଲିଟି ଆପ୍ ସର୍ଚ୍ଚ କରିପାରିବେ ଏବଂ ତା'ପରେ ଆପ୍ଲିକେସନ୍ ଡାଉନଲୋଡ୍ କରିପାରିବେ।
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.4 ମୁଁ କିପରି ମୋ ଆକାଉଣ୍ଟରେ ଲଗ୍ ଇନ୍ କରିବି?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ରେଜିଷ୍ଟ୍ରେସନ୍ ପ୍ରକ୍ରିୟା ସମ୍ପୂର୍ଣ୍ଣ କରିବା ପରେ, ଆପଣ ପ୍ରୋଗ୍ରାମ୍‌ରେ ନାମାଙ୍କନ କରିବେ। କେବଳ ଆପଣଙ୍କ ପଞ୍ଜୀକୃତ ମୋବାଇଲ୍ ନମ୍ବର ଏଣ୍ଟର କରନ୍ତୁ ଏବଂ OTP ସହିତ ଲଗ୍ ଇନ୍ କରନ୍ତୁ।
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.5 ମୁଁ କିପରି ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ସୁପର ପ୍ଲମ୍ବର ଆପ୍ ପ୍ରୋଗ୍ରାମରେ ପଏଣ୍ଟସ୍ ଅର୍ନ କରିବି?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span> ଆପଣ ଅନୁମୋଦିତ ରିଟେଲରଙ୍କ ଠାରୁ ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ଉତ୍ପାଦ କ୍ରୟ କରି ଏବଂ ଆପରେ ଇନଭଏସ୍ ଅପଲୋଡ୍ କରି ପଏଣ୍ଟସ୍ ଅର୍ନ କରିପାରିବେ। ଆପଣଙ୍କ କ୍ରୟ ପ୍ରମାଣର ସଫଳ ବୈଧତା ପରେ, ଆପଣଙ୍କ ଆକାଉଣ୍ଟରେ ଇନଭଏସ୍ ରାଶି ପଏଣ୍ଟଗୁଡ଼ିକ ଯୋଡ଼ାଯିବ।
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.6 ଏହି କାର୍ଯ୍ୟକ୍ରମ ଉଭୟ ରିଟେଲ ଏବଂ ପ୍ରୋଜେକ୍ଟ ଅର୍ଡର ପାଇଁ ଉପଲବ୍ଧ କି?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ନା। ଏହି କାର୍ଯ୍ୟକ୍ରମ କେବଳ ରିଟେଲ ଅର୍ଡର ପାଇଁ ଅଟେ
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.7 ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ସୁପର ପ୍ଲମ୍ବର ପ୍ରୋଗ୍ରାମରେ ପଏଣ୍ଟସ୍ ଆବଣ୍ଟନ ପାଇଁ କେଉଁ ଉତ୍ପାଦ/SKU ଯୋଗ୍ୟ?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ଏହା ଫିନୋଲେକ୍ସ ପାଇପ୍ ର ପ୍ରତ୍ୟେକ ଉତ୍ପାଦ ପାଇଁ ପ୍ରଯୁଜ୍ୟ। ଆପଣଙ୍କୁ କେବଳ ଇନଭଏସ୍ ଅପଲୋଡ୍ କରିବାକୁ ପଡ଼ିବ ଏବଂ ଆପ୍ଲିକେସନ୍‌ରେ ଫିନୋଲେକ୍ସ ଉତ୍ପାଦ କ୍ରୟ ମୂଲ୍ୟ ଉଲ୍ଲେଖ କରିବାକୁ ପଡ଼ିବ। କ୍ରୟ ମୂଲ୍ୟ ପଏଣ୍ଟଗୁଡ଼ିକ ଆପଣଙ୍କ ଆକାଉଣ୍ଟରେ ପଏଣ୍ଟସ୍ ଭାବରେ ଯୋଡ଼ାଯିବ।<br />
                                                            ଯୋଗ୍ୟ ଉତ୍ପାଦଗୁଡ଼ିକ ବିଷୟରେ ଅଧିକ ବିବରଣୀ ପାଇଁ, ଆପଣ ସୋମବାର ରୁ ଶନିବାର ସକାଳ 9.30 ରୁ ସନ୍ଧ୍ୟା 5.30 ମଧ୍ୟରେ 18002024289 ରେ ଆମର ପ୍ରୋଗ୍ରାମ ହେଲ୍ପଡେସ୍କ ସହ ସମ୍ପର୍କ କରିପାରିବେ।
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.8 ଯଦି ମୋ ଆକାଉଣ୍ଟରେ ଥିବା ରିୱାର୍ଡ ପଏଣ୍ଟସ୍ ମୋ ଦ୍ୱାରା ରିପୋର୍ଟ ହୋଇଥିବା କ୍ରୟଠାରୁ କମ୍ ହୁଏ ତେବେ କ'ଣ ହେବ?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ସୋମବାର ରୁ ଶନିବାର ସକାଳ 9.30 ରୁ ସନ୍ଧ୍ୟା 5.30 ମଧ୍ୟରେ 18002024289 ରେ ଆମର ପ୍ରୋଗ୍ରାମ ହେଲ୍ପଡେସ୍କ ସହିତ ସମ୍ପର୍କ କରନ୍ତୁ। ବିକଳ୍ପ ଭାବରେ, ଆପଣ ଅଧିକ ସ୍ପଷ୍ଟତା ପାଇବା ପାଇଁ ଫିନୋଲେକ୍ସ ପାଇପ୍ ସେଲ୍ସ ଟିମ୍ ସହିତ ମଧ୍ୟ ଯୋଗାଯୋଗ କରିପାରିବେ
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.9  ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ସୁପର ପ୍ଲମ୍ବର ଲୟାଲିଟି ପ୍ରୋଗ୍ରାମରେ ପ୍ରତ୍ୟେକ ପୁରସ୍କାର ପଏଣ୍ଟସ୍ର ମୂଲ୍ୟ କେତେ? ">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ଗୋଟିଏ (01) ରିୱାର୍ଡ ପଏଣ୍ଟସ୍ = 0.02 ଟଙ୍କା
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.10 ବୋନସ୍ ପଏଣ୍ଟସ୍:">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>1. ଅଥେଣ୍ଟିକେସନ୍ ପରେ, ଆପଣ ଏକ ଯୋଗଦାନ ବୋନସ୍ ଭାବରେ 10,000 ପଏଣ୍ଟସ୍ ପାଇବେ<br />

                                                            2. ବୋନସ ପଏଣ୍ଟସ୍ ପ୍ରଥମ ରିଡେମ୍ପସନ୍ ଅନୁରୋଧ ପାଇଁ ବ୍ୟବହାର କରାଯାଇପାରିବ ନାହିଁ<br />

                                                            3. ଆପଣ ପରବର୍ତ୍ତୀ ରିଡେମ୍ପସନ୍ ଅନୁରୋଧ ଉପରେ ରିଡେମ୍ପସନ୍ ପାଇଁ ବୋନସ୍ ପଏଣ୍ଟସ୍ ବ୍ୟବହାର କରିପାରିବେ<br />
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.11 ମୁଁ ମୋର ଅର୍ଜିତ ପଏଣ୍ଟଗୁଡ଼ିକୁ ଅନ୍ୟ ପଞ୍ଜୀକୃତ ଫିନୋଲେକ୍ସ ପାଇପ୍ ଏବଂ ଫିଟିଙ୍ଗ୍ ସୁପର ପ୍ଲମ୍ବର ଲୟାଲିଟି ପ୍ରୋଗ୍ରାମ ଆକାଉଣ୍ଟକୁ ଟ୍ରାନ୍ସଫର୍ କରିପାରିବି କି?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ନା, ଆପଣ ଅନ୍ୟ କୌଣସି ପଞ୍ଜିକୃତ ସଦସ୍ୟଙ୍କୁ ଆପଣଙ୍କ ପଏଣ୍ଟସ୍ ଟ୍ରାନ୍ସଫର୍ କରିପାରିବେ ନାହିଁ।
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.12 ମୁଁ କିପରି ମୋର ବାଲାନ୍ସ ପଏଣ୍ଟସ୍ ଯାଞ୍ଚ କରିବି?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ଆପଣ ମୋବାଇଲ୍ ଆପ୍ଲିକେସନରେ ଥିବା 'ହାମ୍‌ବର୍ଗର୍' ଟ୍ୟାବ୍‌ରେ କ୍ଲିକ୍ କରି ଆପଣଙ୍କ ବାଲାନ୍ସ ପଏଣ୍ଟସ୍ ଯାଞ୍ଚ କରିପାରିବେ।
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.13 ପଏଣ୍ଟସ୍‌ର ବୈଧତା କେତେ ଦିନ?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ଏହା ପ୍ରସ୍ତୁତ ହେବାର 3(ତିନି) ବର୍ଷ ପର୍ଯ୍ୟନ୍ତ।
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.14 ରିଡେମ୍ପସନ୍ ପାଇଁ ସର୍ବନିମ୍ନ କେତେ ପଏଣ୍ଟସ୍ ଆବଶ୍ୟକ?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ପ୍ରଥମ ରିଡେମ୍ପସନ୍ 20000 ପଏଣ୍ଟସ୍ ରେ ହୋଇଥାଏ ଏବଂ ପରବର୍ତ୍ତୀ ରିଡେମ୍ପସନ୍ କରିବା ପାଇଁ ସର୍ବନିମ୍ନ 5000 ପଏଣ୍ଟସ୍ ଆବଶ୍ୟକ
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.15 ମୁଁ କିପରି ମୋର ଅର୍ଜିତ ପଏଣ୍ଟସ୍ ରିଡିମ୍ କରିବି?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ଆପଣଙ୍କର ସଞ୍ଚିତ ପଏଣ୍ଟସ୍‌କୁ ରିଡିମ୍ କରିବା ପାଇଁ ବିଭିନ୍ନ ବିକଳ୍ପ ଅଛି। ନିମ୍ନଲିଖିତ ଭାବରେ ଉଲ୍ଲେଖ କରାଯାଇଛି:
                                                            1. ଆଧାର ସହ ଲିଙ୍କ୍ ହୋଇଥିବା ମୋବାଇଲ୍ ନମ୍ବରରେ ପେଟିଏମ୍ ୱାଲେଟ୍ ଟ୍ରାନ୍ସଫର<br />

                                                            2. ଡାଇରେକ୍ଟ ବ୍ୟାଙ୍କ ଟ୍ରାନ୍ସଫର୍<br />

                                                            3. UPI ଟ୍ରାନ୍ସଫର୍<br />

                                                            4. ଇ-ଗିଫ୍ଟ ଭାଉଚର୍<br />

                                                            5. ଫିନୋଲେକ୍ସ ମର୍ଚ୍ଚାଣ୍ଡାଇଜ୍ ଆଇଟମ୍
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.16 ମୁଁ କେତେ ଥର ମୋର ସଞ୍ଚିତ ପଏଣ୍ଟସ୍ ରିଡିମ୍ କରିପାରିବି?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ଯଦି ଆପଣଙ୍କ ଆକାଉଣ୍ଟରେ ସର୍ବନିମ୍ନ 5000 ପର୍ଯ୍ୟାପ୍ତ ବାଲାନ୍ସ ପଏଣ୍ଟସ୍ ଅଛି, ଆପଣ ପ୍ରୋଗ୍ରାମ ଅବଧିରେ କେବେ ବି ରିଡେମ୍ପସନ୍ ଅନୁରୋଧ କରିପାରିବେ।
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.17 ମୁଁ ମୋର ରିଡିମ୍ କରିଥିବା ରିୱାର୍ଡର ସ୍ଥିତି କିପରି ଜାଣିବି?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ଆପଣ ରିଡିମ୍ ପଏଣ୍ଟସ୍ ଟ୍ରାକ୍ ରିୱାର୍ଡସ୍ ଅଧୀନରେ ଆପ୍ଲିକେସନ୍‌ରେ ଆପଣଙ୍କ ରିଡେମ୍ପସନ୍ ର ସ୍ଥିତି ଯାଞ୍ଚ କରିପାରିବେ।<br />
                                                            ଆପଣଙ୍କ EGV/UPI/ପେଟିଏମ୍ ୱାଲେଟ୍ ଟ୍ରାନ୍ସଫର/ଡାଇରେକ୍ଟ ବ୍ୟାଙ୍କ ଟ୍ରାନ୍ସଫର ସଫଳ ହେବା ପରେ ଆପଣଙ୍କୁ ଏକ SMS ଅପଡେଟ୍ ମଧ୍ୟ ମିଳିବ।<br />
                                                            ତେବେ, ଆପଣ ଆପଣଙ୍କ ରିଡେମ୍ପସନର ସାମ୍ପ୍ରତିକ ସ୍ଥିତି ଜାଣିବା ପାଇଁ 18002024289 ରେ ସୋମବାର-ଶନିବାର (ସକାଳ 09.30 ରୁ ଅପରାହ୍ନ 05.30) ରେ ପ୍ରୋଗ୍ରାମ ହେଲ୍ପଡେସ୍କ ସହିତ ଯୋଗାଯୋଗ କରିପାରିବେ।
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.18 ମୁଁ କିପରି ଜାଣିବି ଯେ ମୋ ଦ୍ୱାରା ଦାଖଲ ହୋଇଥିବା କ୍ରୟ ପାଇଁ ମୁଁ ପଏଣ୍ଟସ୍ ପାଇଛି କି ନାହିଁ?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ଇନଭଏସ୍‌ର କ୍ଲିୟର ଫଟୋର ସଫଳ ଅପଲୋଡ୍ ପରେ, ପଏଣ୍ଟଗୁଡ଼ିକ ଆପ୍ଲିକେସନରେ ଅନଅଡିଟେଡ୍ ପଏଣ୍ଟସ୍ ଭାବରେ ଦୃଶ୍ୟମାନ ହେବ ଏବଂ ଆପଣ ଯେଉଁ ସମ୍ପୃକ୍ତ ଅନୁମୋଦିତ ରିଟେଲରଙ୍କଠାରୁ ଫିନୋଲେକ୍ସ ପାଇପ୍ ଉତ୍ପାଦ କିଣିଛନ୍ତି ତାଙ୍କ ଅନୁମୋଦନ ପରେ ହିଁ ଏହା ରିଡେମ୍ପସନ୍ ପାଇଁ ଉପଲବ୍ଧ ହେବ।
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.19 ମୁଁ ରିଡିମ୍ କରିଥିବା ଉତ୍ପାଦ ପାଇବାକୁ କେତେ ସମୟ ଲାଗିବ?">
                                                        <p className="m-0">
                                                            • UPI ଟ୍ରାନ୍ସଫର ଡେଲିଭରି ହେବାକୁ 24 ରୁ 48 ଘଣ୍ଟା ଲାଗିବ<br />

                                                            · ଡାଇରେକ୍ଟ ବ୍ୟାଙ୍କ ଟ୍ରାନ୍ସଫର ଡେଲିଭରି ହେବାକୁ 24 ରୁ 48 ଘଣ୍ଟା ଲାଗିବ<br />

                                                            · ଇ-ଗିଫ୍ଟ ଭାଉଚର ରିଅଲ ଟାଇମ ଭିତ୍ତିରେ ଦିଆଯିବ ଏବଂ ସର୍ବାଧିକ 24 ଘଣ୍ଟା ମଧ୍ୟରେ ଆପ୍‌ରେ ପ୍ରତିଫଳିତ ହେବ<br />

                                                            · ଷ୍ଟକ୍ ଉପଲବ୍ଧତା ଏବଂ ଡେଲିଭରି ଲୋକେସନ୍ ଉପରେ ନିର୍ଭର କରି ଫିନୋଲେକ୍ସ ମର୍ଚ୍ଚାଣ୍ଡାଇଜ୍ 2-3 ସପ୍ତାହ ପର୍ଯ୍ୟନ୍ତ ସମୟ ନେବ                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.20 ଯଦି ମୋର କ୍ରେଡିଟ୍ ହୋଇଥିବା ପଏଣ୍ଟସ୍ ଭୁଲ୍ ଅଟେ କିମ୍ବା ମୋର ଅଂଶଗ୍ରହଣ ପାଇଁ ମୋତେ ପଏଣ୍ଟସ୍ ଆବଣ୍ଟନ କରାଯାଇନାହିଁ, ତେବେ ମୁଁ କ'ଣ କରିପାରିବି?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ଏଭଳି ପରିସ୍ଥିତିରେ, ଆପଣ ଆପ୍‌ରୁ ଆପଣଙ୍କ ସମସ୍ୟା ପାଇଁ ଅନୁରୋଧ କରିପାରିବେ କିମ୍ବା ଆପଣଙ୍କ ପଞ୍ଜୀକୃତ ମୋବାଇଲ୍ ନମ୍ବର ସହିତ ଆମର ହେଲ୍ପଡେସ୍କ 18002024289 ରେ ଆମକୁ କଲ୍ କରି ପାରିବେ ଏବଂ ଆପଣଙ୍କ ସମସ୍ୟା ପାଇଁ ଅନୁରୋଧ ମଧ୍ୟ କରିପାରିବେ। ଆମେ ସମସ୍ୟା ଉପରେ ଧ୍ୟାନ ଦେବୁ ଏବଂ ଯଥାଶୀଘ୍ର ଉତ୍ତର ଦେବୁ।
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.21 ମୁଁ କିପରି ଫିନୋଲେକ୍ସ ପାଇପ୍ ସୁପର ପ୍ଲମ୍ବର ପ୍ରୋଗ୍ରାମ ସେଣ୍ଟରରେ ପହଞ୍ଚିବି?">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ସୋମବାର ରୁ ଶନିବାର ସକାଳ 9:30 ରୁ ସନ୍ଧ୍ୟା 5:30 ପର୍ଯ୍ୟନ୍ତ ଆପଣ ଆମର ପ୍ରୋଗ୍ରାମ୍ ହେଲ୍ପଲାଇନ୍ ନମ୍ବର 18002024289 ରେ ଆମ ସହ ଯୋଗାଯୋଗ କରିପାରିବେ।
                                                        </p>
                                                    </AccordionTab>
                                                    <AccordionTab header="ପ୍ରଶ୍ନ.22  ଦୟାକରି ଆପଣ ଫିନୋଲେକ୍ସ ସୁପର ପ୍ଲମ୍ବର ଲୟାଲିଟି ପ୍ରୋଗ୍ରାମର ଅନ୍ୟ କିଛି ଲାଭ ବିଷୟରେ କହିପାରିବେ କି? ">
                                                        <p className="m-0">
                                                            <span>ଉତ୍ତର:</span>ହଁ, ପ୍ଲମ୍ବର ଏବଂ ସେମାନଙ୍କ ପରିବାର ପାଇଁ ଏକାଧିକ ଏନଗେଜମେଣ୍ଟ କାର୍ଯ୍ୟକଳାପ ହେବ।
                                                        </p>
                                                    </AccordionTab>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </section >
    )
}
