import React, { useState } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom'
import ResponsivePagination from 'react-responsive-pagination';
import Oval from '../Loader/CircleLoade';
import longarrow from '../../../assets/img/longarrow.svg'
import * as Constants from "../../../Constants/index"
import axios from 'axios'
import swal from 'sweetalert'
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';


export default function Anomoly() {
    const navigate = useNavigate()
    const userType = localStorage.getItem('user_type')
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState(null)
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10)
    const [filterData, setFilterData] = useState({})

    const searchFormik = useFormik({
        initialValues: {
            startDate: '',
            endDate: ''
        },
        validate: (data) => {
            let errors = {}
            if (data.startDate === '' && data.endDate !== '') {
                errors.startDate = 'Please select date'
            }
            if (data.startDate !== '' && data.endDate === '') {
                errors.endDate = 'Please select date'
            }
            if (data.startDate && data.endDate && data.startDate > data.endDate) {
                errors.endDate = 'Please select date'
            }
            if (data.startDate && data.endDate && data.startDate > data.endDate) {
                errors.endDate = 'End date should be greater than start date'
            }
            // if (data.startDate && data.endDate) {
            //     const maxEndDate = new Date(data.startDate)
            //     maxEndDate.setDate(maxEndDate.getDate() + 30) // Adjust the range as needed (e.g., 31 days)

            //     if (data.endDate > maxEndDate) {
            //         errors.endDate = 'End date should be within 31 days from the start date.'
            //     }
            // }
            return errors
        },
        onSubmit: (data) => {
            setCurrentPage(1)
            getReportData(data, 1)
            setFilterData(data)
        },
    })
    const isSearchFormFieldValid = (name) => !!(searchFormik.touched[name] && searchFormik.errors[name])
    const getSearchFormErrorMessage = (name) => {
        return isSearchFormFieldValid(name) && <small className="report-error-field">{searchFormik.errors[name]}</small>
    }



    const getReportData = async (data, pageNum) => {
        setLoading(true);
        if (!token) {
            navigate('/')
        } else {
            let stDate
            let endDate

            var momentObj1 = moment(data.startDate)
            var momentObj2 = moment(data.endDate)
            if (data.startDate && data.endDate) {
                var startFormattedDate = momentObj1.format('YYYY-MM-DD HH:mm:ss')
                var startFormattedDate4 = momentObj2.format('YYYY-MM-DD 23:59:59')
                stDate = `start=${startFormattedDate}`
                endDate = `&end=${startFormattedDate4}`
            } else {
                stDate = ''
                endDate = ''
            }

            await axios.get(Constants.baseUrl + `reports/Anomoly/list?${stDate}${endDate}&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${pageNum}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                }
            }).then((res) => {
                const dt = res.data
                if (dt.result === 'success') {
                    setReportData(dt?.output?.results)
                    setTotalPages(dt?.output?.totalPages)
                    setLoading(false)
                } if (dt.result === 'error' || dt.result === 'dialog') {
                    setReportData(null)
                    setTotalPages(0)
                    setLoading(false)
                }
            }).catch((err) => {
                setLoading(false)
            })
        }
    }

    const download = (data) => {
        setLoading(true)
        try {
            let stDate
            let endDate

            var momentObj1 = moment(data.startDate)
            var momentObj2 = moment(data.endDate)
            if (data.startDate && data.endDate) {
                var startFormattedDate = momentObj1.format('YYYY-MM-DD HH:mm:ss')
                var startFormattedDate4 = momentObj2.format('YYYY-MM-DD 23:59:59')
                stDate = `start=${startFormattedDate}`
                endDate = `&end=${startFormattedDate4}`
            } else {
                stDate = ''
                endDate = ''
            }


            axios.get(Constants.baseUrl + `reports/download/AnomolyReport?${stDate}${endDate}`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'
            }).then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    const fileData = new Blob([res.data], { type: 'text/csv' });
                    saveAs(fileData, 'anomolyReport.csv');
                } else {
                    swal({
                        text: res.data.msg,
                        // icon: 'error',
                        timer: 2000,
                        buttons: false,
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const areAllValuesBlank = () => {
        const { startDate, endDate } = searchFormik.values
        return (
            !startDate && !endDate
        )
    }
    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">Anomoly Report  </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <form onSubmit={searchFormik.handleSubmit}>
                                    <div className="row clearfix mb-15 callwell">
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <DatePicker
                                                id="startDate"
                                                selected={searchFormik.values.startDate}
                                                onChange={(date) => searchFormik.setFieldValue('startDate', date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="frmcntrl"
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                                placeholderText='From date'
                                            />
                                            {getSearchFormErrorMessage('startDate')}
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <DatePicker
                                                id="endDate"
                                                selected={searchFormik.values.endDate}
                                                onChange={(date) => searchFormik.setFieldValue('endDate', date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="frmcntrl"
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                                placeholderText='To date'
                                            />
                                            {getSearchFormErrorMessage('endDate')}
                                        </div>
                                        <div className="col-md-3 col-sm-3 col-xs-4">
                                            <button className="btn login_white_btn" type="submit" disabled={areAllValuesBlank()}> Search <img src={longarrow} alt='' /></button>
                                        </div>
                                    </div>
                                </form>
                                <div className="row">
                                    <div className="col-md-12">
                                        {reportData === null || userType === 'RETAILER' || userType === 'SALES' ? '' :
                                            <button className="btn login_btn fa fa-file-excel-o pull-right mt-10 mb-10" type='submit' onClick={() => download(searchFormik.values)}
                                            > Export</button>}
                                        <div className="carrd1">
                                            <div className="card-body table-responsive">
                                                <table className="table table-bordered mytable mb_01 " >
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>ZSM Name</th>
                                                            <th>ZSM Mobile</th>
                                                            <th>RSM Name</th>
                                                            <th>RSM Mobile Number</th>
                                                            <th>ASM Name</th>
                                                            <th>ASM Mobile Number</th>
                                                            <th>TSE Name</th>
                                                            <th>TSE Mobile Number</th>
                                                            <th>Plumber Name</th>
                                                            <th>Plumber Unique ID (Mobile Number)</th>
                                                            <th>Date Of Registration</th>
                                                            <th>Purchase Amount in last month </th>
                                                            <th>Purchase Amount in last Quarter (Past 3 months)</th>
                                                            <th>No of Retailer transacted in last month</th>
                                                            <th>No of Retailer transacted in last 3 months</th>
                                                            <th>Number of redemptions</th>
                                                        </tr>
                                                    </thead>
                                                    {reportData === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="11" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {reportData && reportData.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                const dateOfReg = item[10] ? moment(item[10]).format('DD-MM-YYYY') : ''
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            <td>{item[0]}</td>
                                                                            <td>{item[1]}</td>
                                                                            <td>{item[2]}</td>
                                                                            <td>{item[3]}</td>
                                                                            <td>{item[4]}</td>
                                                                            <td>{item[5]}</td>
                                                                            <td>{item[6]}</td>
                                                                            <td>{item[7]}</td>
                                                                            <td>{item[8]}</td>
                                                                            <td>{item[9]}</td>
                                                                            <td>{dateOfReg}</td>
                                                                            <td>{item[11]}</td>
                                                                            <td>{item[12]}</td>
                                                                            <td>{item[13]}</td>
                                                                            <td>{item[14]}</td>
                                                                            <td>{item[15]}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => {
                                                            setCurrentPage(page);
                                                            getReportData(filterData, page)
                                                        }} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}
