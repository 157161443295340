import React from 'react'

export default function Gujrat() {
    return (
        <>
            <div className="terms">
              <ol>
  <li>આ ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ સુપર પ્લમ્બર લોયલ્ટી પ્રોગ્રામ ફક્ત ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ દ્વારા ધ્યાનમાં લેવામાં આવતા PAN ઇન્ડિયા પ્લમ્બર માટે ડિઝાઇન કરવામાં આવ્યો છે.</li>
  <li>આ પ્રોગ્રામ 16મી ઑક્ટોબરથી શરૂ થઈ રહ્યો છે અને પ્રોગ્રામમાં ભાગ લેવો સ્વૈચ્છિક છે.</li>
  <li>પ્રોગ્રામમાં પ્લમ્બરની નોંધણી સ્વૈચ્છિક છે.</li>
  <li>બધા યુઝર્સ/સભ્યોએ પ્રોગ્રામના નિયમો અને શરતો અને ગોપનીયતા નીતિને વિગતવાર વાંચી છે અને તેનું પાલન કરવા સંમત છે.</li>
  <li>આ પ્લૅટફૉર્મ અને લૉયલ્ટી પ્રોગ્રામના તમામ યુઝર્સ/સભ્યોએ ડિજિટલ પર્સનલ ડેટા પ્રોટેક્શન એક્ટ, 2023 અનુસાર, નામ, મોબાઈલ નંબર, ફોટોગ્રાફ, આધાર કાર્ડ, જેવા તેમના અંગત ડેટાનો ઉપયોગ કરવા માટે EBPL અને FILને સંમતિ આપી છે અને તેમની સંમતિ આપી છે. પાન કાર્ડ, EBPL/ FIL દ્વારા ઉપરોક્ત વ્યક્તિગત ડેટાની પ્રક્રિયા માટે અને તેના કાયદેસરના વ્યવસાયિક હેતુઓ માટે તૃતીય પક્ષોને એકંદર નોંધણી ડેટા જાહેર કરવાનો અધિકાર, જેમાં જાહેરાતો અને અન્ય સેવા ઑફર્સના લક્ષ્યાંકોનો સમાવેશ છે પણ તેટલા સુધી સીમિત નથી.</li>
  <li>રિડેમ્પશન ઑનલાઈન રિડેમ્પશન વિકલ્પો દ્વારા કરી શકાય છે.</li>
  <li>અધિકૃત ડીલરો અને ફિનોલેક્સ પાઈપ્સ & ફિટિંગ્સના છૂટક વિક્રેતાઓ પાસેથી ખરીદેલ ઉત્પાદનો (આ પ્રોગ્રામ હેઠળ આવરી લેવામાં આવ્યા છે) આ પ્રોગ્રામ માટે ધ્યાનમાં લેવામાં આવશે.</li>
  <li>ફિનોલેક્સ પાઇપ દ્વારા લેખિતમાં મંજૂર કર્યા સિવાય આ પ્રોગ્રામને કોઈપણ અન્ય સ્કીમ/પ્રોગ્રામ/ઑફર સાથે ક્લબ કરી શકાશે નહીં.</li>
  <li>ફિનોલેક્સ પાઇપ્સ એન્ડ ફિટિંગ્સ સેલ્સ ટીમ દ્વારા માન્યતાને આધીન તમામ પ્લમ્બર ફિનોલેક્સ સુપર પ્લમ્બર લોયલ્ટી પ્રોગ્રામમાં નોંધણી માટે પાત્ર છે. નોંધણી/નોંધણી કરેલ સભ્યો ફિનોલેક્સ પાઈપ્સ અને ફીટીંગ્સના કર્મચારીઓ, વિક્રેતાઓ, એજન્ટો અથવા કાર્યક્રમના વહીવટ સાથે સંકળાયેલી કોઈપણ વ્યક્તિ અથવા સંસ્થા ન હોવી જોઈએ અને આવી કોઈ વ્યક્તિના પારિવારિક સંબંધ ન હોવા જોઈએ.</li>
  <li>પ્રોગ્રામના લાભોનો આનંદ માણવા માટે પાઇપ્સ એન્ડ ફિટિંગ્સ સુપર પ્લમ્બર લૉયલ્ટી પ્રોગ્રામમાં તમામ નોંધણી માન્યતા આવશ્યક છે.</li>
  <li>લૉટરી (રેગ્યુલેશન) એક્ટ, 1998 હેઠળ આવતા કોઈપણ લાભ અથવા પુરસ્કાર તમિલનાડુ રાજ્યમાં નોંધાયેલા અને કાર્યરત સભ્યો માટે લાગુ થશે નહીં. 1 પૉઇન્ટનું મૂલ્ય INR 0.02/- હશે.</li>
  <li>ઉપાર્જિત પોઈન્ટ્સ Paytm વૉલેટ, UPI, બેંક ટ્રાન્સફર પ્રોડક્ટ્સ અને ઈ-વાઉચર સામે રિડીમ કરવામાં આવશે. ફિનોલેક્સ પાઈપ્સ & ફિટિંગ્સદ્વારા કોઈ વધુ શરત લાગુ ન થાય ત્યાં સુધી ભૌતિક ઉત્પાદનો પુરસ્કાર સૂચિમાં ઉપલબ્ધ છે.</li>
  <li>કોઈપણ ડુપ્લિકેટ ઇન્વૉઇસ અપલોડ કરવા પર કોઈ લૉયલ્ટી રિવોર્ડ પોઈન્ટ્સ આપવામાં આવશે નહીં.</li>
  <li>ન તો આ પ્રોગ્રામ કે ન તો તેના નિયમો અને શરતોને સંયુક્ત સાહસ, એજન્સી સંબંધ અથવા ફ્રેન્ચાઈઝીની મંજૂરી તરીકે સમજવામાં આવશે.</li>
  <li>કરવાનો અથવા તેના માટે કોઈપણ કારણ(ઓ) અને સૂચના(ઓ) આપ્યા વિના તેમ કરવાનો અધિકાર અનામત રાખે છે અને આ સંદર્ભમાં ફિનોલેક્સ પાઇપ્સનો નિર્ણય અંતિમ માનવામાં આવશે અને બંધનકર્તા રહેશે.</li>
  <li>ફિનોલેક્સ પાઈપ્સ & ફિટિંગ્સનોંધણી/નોંધાયેલ સભ્યો દ્વારા કોઈપણ જરૂરી દસ્તાવેજો રજુ ન કરવા અને/અથવા બનાવટી, ખોટા, ગેરમાર્ગે દોરનારા અથવા કપટપૂર્ણ દસ્તાવેજ(ઓ)/ માહિતી પૂરી પાડવાની રજૂઆતના કિસ્સામાં આ પ્રોગ્રામ હેઠળના તમામ લાભોને રદબાતલ કરવાનો અને પાછા વસૂલી લેવાનો અધિકાર અનામત રાખે છે.</li>
  <li>રજિસ્ટર્ડ સભ્ય પ્રોગ્રામમાં અથવા અન્યથા કરવામાં આવી રહેલા કોઈપણ અન્યાયી વેપાર વ્યવહારના અવલોકન/અહેવાલના આધારે પ્રોગ્રામ અને અન્ય આવા પ્રોગ્રામ્સ /પ્રોત્સાહન યોજનાઓમાંથી ગેરલાયક ઠરવા/બાકાતી માટે જવાબદાર છે અને તેની/તેણીની ઈનામની રકમ પણ જપ્ત થઈ શકે છે જે આખરી, નિર્ણાયક અને બંધનકર્તા હશે અને વિવાદ કે પ્રશ્ન કરવા માટે તે જવાબદાર રહેશે નહીં.</li>
  <li>ફિનોલેક્સ પાઈપ્સ & ફિટિંગ્સ સ્કીમમાં પાછી ખેંચવા અથવા ફેરફાર કરવાને કારણે અથવા અન્ય કોઈપણ કારણોસર નોંધાયેલા સભ્યો સહિત કોઈપણ વ્યક્તિને પ્રત્યક્ષ કે પરોક્ષ કોઈપણ નુકસાન અથવા નુકસાન માટે જવાબદાર રહેશે નહીં.</li>
  <li>બધા નોંધાયેલા સભ્યોને ફિનોલેક્સ પાઈપ્સ & ફિટિંગ્સદ્વારા પૂર્વ-નિર્ધારિત ઍવોર્ડિંગ મેટ્રિક્સ સેટ મુજબ પુરસ્કાર આપવામાં આવશે.</li>
  <li>હાલના પ્રોગ્રામ હેઠળના પોઈન્ટ્સ અથવા અન્ય કોઈપણ લાભો બિન-તબદીલીપાત્ર છે.</li>
  <li>બધા રજિસ્ટર્ડ સભ્યો અનિવાર્યપણે સ્વીકારે છે કે તમામ નિયમો અને શરતો તેમના દ્વારા સંપૂર્ણ રીતે વાંચવામાં અને સમજવામાં આવી છે.</li>
  <li>ફિનોલેક્સ પાઈપ્સ & ફિટિંગ્સ ગિફ્ટ/કૅટલૉગ સૂચિમાં પ્રદાન કરવામાં આવેલ તૃતીય-પક્ષ માલ પર ગુણવત્તા, વેચાણ પછીની સેવા, વોરંટી, પ્રદર્શન વગેરે જેવી કોઈપણ પ્રકારની જવાબદારી લેતા નથી.</li>
  <li>ફિનોલેક્સ પાઈપ્સ & ફિટિંગ્સ સભ્યો દ્વારા એન્ટ્રીઓના ટ્રાન્સમિશન અથવા સબમિટ કરવામાં કોઈપણ તકનીકી, અથવા ભૌતિક વિલંબ અથવા કોઈપણ વિક્ષેપો, નુકસાન, નુકસાન, કૉમ્પ્યુટર-સંબંધિત ખામી/નિષ્ફળતા કે જે સભ્યોની ભાગીદારીને અસર કરે છે, અથવા કોઈપણ કુદરતી પરિબળોની ઘટનાની પરિસ્થિતિઓ, સરકારી ક્રિયાઓ/પ્રતિબંધો/લૉકડાઉન, રોગચાળો/મહામારી તથા ફિનોલેક્સ પાઈપ્સ & ફિટિંગ્સદ્વારા નિયુક્ત તૃતીય પક્ષો દ્વારા કરવામાં આવેલ કૃત્યો, ભૂલો, અવગણના માટે થતા નુકસાન શરતો માટે જવાબદાર રહેશે નહીં.</li>
  <li>ફિનોલેક્સ પાઈપ્સ & ફિટિંગ્સ વિવિધ કારણો જેવા કે આબોહવાની પરિસ્થિતિઓ, શ્રમ અશાંતિ, નાદારી, વ્યવસાયિક આવશ્યકતાઓ, અથવા ઑપરેશનલ અને તકનીકી સમસ્યાઓ અથવા પ્રોગ્રામ હેઠળ કામગીરીમાં વિલંબનું કારણ બને તેવા અન્ય કોઈપણ મુદ્દાઓ માટે સ્ટૉક (સ્ટૉક્સ)ની અનુપલબ્ધતા માટે પણ જવાબદાર રહેશે નહીં.</li>
  <li>પોઈન્ટના ઉપયોગ અથવા બિનઉપયોગને કારણે ઉદ્ભવતા કોઈપણ વિવાદો, વિવાદો અને વિવાદો ફિનોલેક્સ પાઈપ્સ અને ફિટિંગ્સના ધ્યાન પર લાવવામાં આવશે. ફિનોલેક્સ પાઈપ્સ એન્ડ ફીટીંગ્સ વહેલામાં વહેલી તકે વિવાદને ઉકેલવા માટે એક સમિતિની નિમણૂક/અધિકૃત કરશે. પ્લમ્બર્સને પ્રોત્સાહિત કરવા વ્યવસાયિક વ્યવહારમાં પારદર્શિતા લાવી અને દ્વારા વર્તમાન કાર્યક્રમ પુરસ્કારોનું વિતરણ. પ્લમ્બર્સ પ્રત્યેની કોઈપણ ફરજિયાત જવાબદારી માટે તે ફિનોલેક્સ પાઇ</li>




                </ol>

            </div>
        </>
    )
}
