import React, { useState } from "react";
import "./style.css";

export default function Marathi() {
  return (
    <div className="privacy">
      <ol className="mainContainer1">
        <span>
          <li>
            <h3>Introduction</h3>
          </li>
          <p>
            This Privacy Notice is intended to describe the practices Finolex
            Industries Limited (hereinafter referred to as FIL/Finolex) follows
            in relation to our website www.finolexpipes.com with respect to the
            privacy of all individuals whose personal data is collected,
            processed and stored. This Privacy Notice should be read together
            with the data privacy policy, and in case of any conflict with the
            policy, the terms of this Privacy Notice will prevail. Please read
            this Privacy Notice carefully.
          </p>
        </span>
        <span>
          <li>
            <h3>Who manages the website?</h3>
          </li>
          <p>
            “FIL” is a separate legal entity and can determine the purposes and
            means for data processing (i.e., act as a data controller or in a
            similar capacity). The entity that is acting as data controller (or
            similar capacity) by providing this website on which your personal
            data will be processed and stored is:
            <ul>
              <li>
                For the personal data of FIL personnel: The data fiduciary is
                Finolex Industries Limited, the entity which employs you.
              </li>
              <li>
                For the personal data of third-party personnel (including FIL
                clients): The data controller is the Finolex Industries Limited
                local member firm with which the third party has a relationship.
              </li>
            </ul>
            <p>
              The personal data collected by the website may be shared by the
              above data controller with one or more member firms.
            </p>
            <span></span>
            <p>
              Our Commitment -
              <ul>
                <li>
                  Your information will not be shared, rented, or sold to any
                  third-party service provider/s
                </li>
                <li>
                  We use state-of-the-art data security measures to protect your
                  information from unauthorized users.
                </li>
              </ul>
            </p>
          </p>
        </span>
        <span>
          <li>
            <h3>How does the website process personal data?</h3>
            <p>
              Processing of your personal data is necessary for the purposes of
              the legitimate business purposes/ interests pursued by the data
              fiduciary or by a third party, except where such interests are
              overridden by the interests or fundamental rights and freedoms of
              the data subject which require protection of personal data.
            </p>
            <p>
              You have the right to object at any time, on grounds relating to
              your particular situation, to the processing of personal data.{" "}
            </p>
            <p>
              Acceptance of our Terms and Conditions and this Privacy Policy
              will be deemed as the customer’s consent to the processing of data
              of the end-user/employee on behalf of the customer/organization
              for all purposes detailed in this document.
            </p>
          </li>
        </span>
        <span>
          <li>
            <h3>What type of personal data is processed on the website?</h3>
            <p>
              The website processes these personal data categories:
              <ul>
                <li>
                  <strong>Name of the individual</strong>
                </li>
                <li>
                  <strong>Phone number</strong>
                </li>
                <li>
                  <strong>Gender</strong>
                </li>
                <li>
                  <strong>Bank details</strong>
                </li>
                <li>
                  <strong>GST number</strong>
                </li>
                <li>
                  <strong>PAN card number</strong>
                </li>
                <li>
                  <strong>Aadhar card details</strong>
                </li>
                <li>
                  <strong>
                    Complete address of that individual – State, city, pincode
                  </strong>
                </li>
              </ul>
            </p>
          </li>
        </span>
        <span>
          <li>
            <h3>Who can access your personal data?</h3>
            <p>
              Your personal data may be processed by FIL and its affiliates for
              the purposes outlined in this Privacy Notice. Additionally,
              selected third-party service providers acting on our behalf may
              process your personal data to fulfil the specified purposes, under
              strict confidentiality and in compliance with applicable data
              protection laws. These third parties may include, but are not
              limited to, cloud service providers, payment processors, and
              analytics services. We ensure that all third-party processors
              adhere to appropriate data protection standards and implement
              adequate security measures to safeguard your personal data
            </p>
            <p>
              To the extent that personal data has been rendered anonymous in
              such a way that you or your device are no longer reasonably
              identifiable, such information will be treated as non-personal
              data and the terms of this Privacy Notice will not apply.
            </p>
          </li>
        </span>
        <span>
          <li>
            <h3>Data retention</h3>
            <p>
              Our policy is to retain personal data only for as long as it is
              needed for the purposes described in the section “How does the
              website process personal data”. Retention periods vary in
              different jurisdictions and are set in accordance with local
              regulatory and professional retention requirements. In order to
              meet our professional and legal requirements, to establish,
              exercise or defend our legal rights and for archiving and
              historical purposes, we need to retain information for significant
              periods of time. The policies and/or procedures for the retention
              of personal data in the website are:
            </p>
            <p>
              The total retention period is defined and will be implemented in
              accordance with the FIL data retention policy and applicable data
              protection laws. Log Data will be retained in accordance with the
              IT Logging Policy and CERT-IN guidelines. After the end of the
              data retention period, your personal data will be deleted.
            </p>
            <p>
              We shall, unless retention is necessary for compliance with any
              law for the time being in force, erase personal data if you
              withdraw consent as soon as it is reasonable to assume that the
              specified purpose of such data is no longer necessary. However, if
              necessary, as per policy we may retain your information for our
              compliance with a legal, accounting, or reporting obligation or
              for archiving purposes as per the requirement of any applicable
              law in force and/or the Act.
            </p>
          </li>
        </span>
        <span>
          <li>
            <h3>Security</h3>
            <p>
              FIL protects the confidentiality and security of information it
              obtains in the course of its business. Access to such information
              is limited, and policies and procedures are in place that are
              designed to safeguard the information from loss, misuse, and
              improper disclosure. However, please be aware that in spite of us
              putting in reasonable efforts to protect your personal
              information, and regardless of any security measures we use, we
              cannot and do not guarantee the absolute protection and security
              of any personal data stored with us.
            </p>
          </li>
        </span>
        <span>
          <li>
            <h3>Cookies & Tracking Technologies</h3>
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.
            </p>
            <p>
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. You can instruct your browser
              to refuse all cookies or to indicate when a cookie is being sent.
              However, if you do not accept cookies, you may not be able to use
              some portions of our Service.
            </p>
          </li>
        </span>
        <span>
          <li>
            <h3>Controlling your personal data</h3>
            <p>
              FIL will not transfer your personal data to third parties (other
              than any external parties referred to in section 6 above) unless
              we have your consent or are required by law to do so.
            </p>
          </li>
        </span>
        <span>
          <li>
            <h3>Your rights in relation to your personal data</h3>
            <p>
              Depending on the applicable jurisdiction, you may have certain
              rights in relation to your personal data, including:
            </p>
            <ul>
              <dl>
                To request details of the personal data FIL processes about you
                and to access the personal data that FIL processes about you
                (“right to access”)
              </dl>
              <dl>
                To have your personal data corrected, for example, if it is
                incomplete or incorrect (“right to correction”)
              </dl>
              <dl>
                To restrict or object to the processing of personal data or
                request the erasure of your personal data (“right to object”)
              </dl>
              <dl>
                To receive a copy of the personal data which you have provided
                to FIL in a structured, commonly used and machine-readable
                format which you can re-use for your own purposes (“right to
                data portability”)
              </dl>
              <dl>
                Where you have provided consent to the processing of your
                personal data, the right to withdraw your consent.(“ right to
                opt-out”)
              </dl>
              <dl>
                Where you can nominate an individual to exercise the above
                mentioned data subject rights in case of death or incapacity
                (“right to nominate”)
              </dl>
              <dl>
                The right to complain to a data protection authority (see
                section “Complaints”)
              </dl>
            </ul>
          </li>
        </span>
        <span>
          <li>
            <h3>Complaints</h3>
            <p>
              If you are concerned about an alleged breach of privacy law or any
              other regulation, contact
              <p>
                <ul>
                  <di>
                    <strong>Name-</strong> Harish Dash
                  </di>
                  <dl>
                    <strong>Designation-</strong> CIO
                  </dl>
                  <dl>
                    <strong>Email ID-</strong>
                    <a href="mailto:hcd@finolexind.com">hcd@finolexind.com</a>
                  </dl>
                  <dl>
                    <strong>Office Address-</strong>
                    Indiqube The Kode, Baner - Pashan Link Rd, Baner, Pune,
                    Maharashtra 411045
                  </dl>
                </ul>
              </p>
              If you are not satisfied with how FIL resolved your complaint, you
              may have the right to complain to the data protection board. You
              may also have the right to refer the matter to a court of
              competent jurisdiction.
            </p>
          </li>
        </span>
        <span>
          <li>
            <h3>Additional Notices</h3>
            <p>
              We may update and amend this privacy notice and the terms and
              conditions from time to time by posting an amended version on our
              website. The amended version will be effective as of the date it
              is published. When we make material changes to this privacy
              notice, we’ll provide users with notice as appropriate under the
              circumstances, e.g., by displaying a prominent notice on the
              website or by sending an email.
            </p>
          </li>
        </span>
        <span>
          <li>
            <h3>Contact us</h3>
            <p>
              If you have additional questions or concerns, contact our FIL
              representative via email-{" "}
              <a href="mailto:helpdeskcorp@finolexind.com">
                helpdeskcorp@finolexind.com
              </a>
            </p>
          </li>
        </span>
      </ol>
    </div>
  );
}
