import React, { useState, useEffect } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import longarrow from '../../../assets/img/longarrow.svg'
import { useNavigate } from 'react-router-dom'
import * as Constants from "../../../Constants/index"
import axios from 'axios'
import Oval from '../Loader/CircleLoade';
import swal from 'sweetalert'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/minimal.css';
export default function CheckHistory() {
    const navigate = useNavigate()
    const [results, setResults] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [loading, setLoading] = useState(false);
    const [searchRecord, setSearchRecord] = useState()
    const [isVisible, setIsVisible] = useState(false);
    const [searchUser, setSearchUser] = useState('')
    const [limit] = useState('10')
    // let gst = localStorage.getItem('gst')


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        if (currentPage !== 1) {
            checkHistoryData(searchUser);
        } else {
            checkHistoryData('');
        } return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [currentPage]);

    const checkHistoryData = async (userName) => {
        let token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
        } else {
            const searchUserVal = userName === '' ? '' : `&name=${userName}`
            try {
                setLoading(true)
                await axios.get(Constants.baseUrl + `program/child/ticket?sortBy=type&sortAsc=true&limit=${limit}&page=${currentPage}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        // company: Constants.company,
                        program: Constants.program,
                    }
                })
                    .then((res) => {
                        if (res.status === 201) {
                            if (res.data.code === 10001) {
                                setLoading(false);
                                setResults(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                            } else if (res.data.code === 12002) {
                                setLoading(false);
                                setSearchRecord(res.data.output.results)
                            } else {
                                swal({
                                    text: res.data.msg,
                                    icon: 'error',
                                    timer: 2000,
                                    buttons: false,
                                })
                            }
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    })

            } catch (error) {
                console.log(error)
            }
        }

    };

    const handleSearchQuery = (event) => {
        setSearchUser(event.target.value)
        if (event.target.value === '') {
            checkHistoryData('')
            setSearchRecord()
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            checkHistoryData(searchUser);
        }
    };

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section class="blog-area pt182">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 tab mb-20">
                            <ul class="nav nav-tabs" role="tablist">
                                <li role="presentation" class="active"><a href="#Section1" role="tab" data-toggle="tab">Check Request History  </a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="all-single-blog rpt">
                                <div class="row clearfix mb-15 callwell">
                                    <div class="col-md-3 col-sm-12 col-xs-8">
                                        <input class="frmcntrl" placeholder="Name/Mobile Number" type="text" value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
                                    </div>
                                    <div class="col-md-3 col-sm-3 col-xs-4">
                                        <button type="button" class="btn login_white_btn" disabled={searchUser.trim() === ''}
                                            onClick={() => checkHistoryData(searchUser)}> Search <img src={longarrow} alt='' /></button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="carrd1">
                                            <div class="card-body table-responsive">
                                                <table class="table table-bordered mytable mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Date of Concern Request</th>
                                                            <th>Concern Requested To </th>
                                                            <th>Request of Concern</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {results && results.map((item, index) => {
                                                            const serialNumber = (currentPage - 1) * limit + index + 1;
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{serialNumber}</td>
                                                                        <td>{item.createdDateTime}</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td>{item.status}</td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Link id="scrollUp" to="" className={`scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`} style={{ position: 'fixed', zIndex: '2147483647' }} onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp} /></Link>

            <Footer />
        </>
    )
}
