import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
export default function Protected({ Cmp, requiredRoles }) {
    // const { Cmp } = props;
    const navigate = useNavigate()
    useEffect(() => {
        let userType = localStorage.getItem('user_type');
        if (!userType || !requiredRoles || !requiredRoles.includes(userType)) {
            navigate('/');
        }
    }, [requiredRoles, navigate]);

    return (
        <>
            <div><Cmp /></div>
        </>
    )
}
