import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";

export default function Gujrat() {
  return (
    <section className="blog-area pt182">
      <div className="container">
        <div className="row">
          <div className="col-md-12 tab mb-20">
            <ul className="nav nav-tabs" role="tablist">
              {/* <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">અવારનવાર પૂછાતા પ્રશ્નો </Link></li> */}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="all-single-blog rpt">
              <div className="carrd1 requestbox">
                <div className="card-body pad30">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <Accordion activeIndex={0}>
                          <AccordionTab header="પ્ર.1 હું પ્રોગ્રામ માટે કેવી રીતે નોંધણી કરાવી શકું?">
                            <p className="m-0">
                              <span>જવાબ:</span>Google Play Store પરથી ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ સુપર પ્લમ્બર લૉયલ્ટી પ્રોગ્રામ ઍપ્લિકેશન ડાઉનલોડ કરો અથવા ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ સેલ્સ ટીમ પણ વૅબ ઍપ્લિકેશન દ્વારા તેમના લૉગિનનો ઉપયોગ કરીને તમારી નોંધણી કરાવી શકે છે.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.2 ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ સુપર પ્લમ્બર લૉયલ્ટી પ્રોગ્રામમાં ભાગ લેવા માટે કોણ પાત્ર છે?">
                            <p className="m-0">
                              <span>જવાબ:</span>માન્ય આધાર કાર્ડ ધરાવતો અને 18 વર્ષથી વધુ ઉંમરના કોઈપણ પ્લમ્બર. જેઓ અધિકૃત રિટેલર પાસેથી ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ ઉત્પાદનો ખરીદે છે તેઓ આ પ્રોગ્રામમાં ભાગ લેવા માટે પાત્ર છે
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.3 હું ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ સુપર પ્લમ્બર લૉયલ્ટી પ્રોગ્રામ કેવી રીતે ડાઉનલોડ કરી શકું?">
                            <p className="m-0">
                              <span>જવાબ:</span>એન્ડ્રોઇડ યુઝર્સ ગૂગલ પ્લે સ્ટોર પર જઈને ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ સુપર પ્લમ્બર લૉયલ્ટી ઍપ શોધી અને પછી ઍપ્લીકેશન ડાઉનલોડ કરી શકે છે.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.4 હું મારા એકાઉન્ટમાં કેવી રીતે લૉગ ઇન કરી શકું?">
                            <p className="m-0">
                              <span>જવાબ:</span>નોંધણી પ્રક્રિયા પૂર્ણ કર્યા પછી, તમને પ્રોગ્રામમાં નોંધણી કરવામાં આવશે. ફક્ત તમારો રજિસ્ટર્ડ મોબાઇલ નંબર દાખલ કરો અને OTP વડે લૉગ ઇન કરો.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.5 ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ સુપર પ્લમ્બર ઍપ પ્રોગ્રામમાં હું કેવી રીતે પોઈન્ટ્સ કમાઈ શકું?">
                            <p className="m-0">
                              <span>જવાબ:</span>તમે અધિકૃત રિટેલર્સ પાસેથી ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ પ્રોડક્ટ્સ ખરીદીને અને ઍપમાં ઇન્વૉઇસ અપલોડ કરીને પોઈન્ટ કમાઈ શકો છો. તમારી ખરીદીના પુરાવાની સફળ માન્યતા પછી, તમારા ખાતામાં ઇન્વૉઇસ રકમના પોઈન્ટ ઉમેરવામાં આવશે.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.6 શું આ પ્રોગ્રામ રિટેલ અને પ્રોજેક્ટ ઑર્ડર બંને માટે છે?">
                            <p className="m-0">
                              <span>જવાબ:</span>ના. પ્રોગ્રામ ફક્ત છૂટક ઑર્ડર માટે છે
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.7 ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ સુપર પ્લમ્બર પ્રોગ્રામમાં કયા ઉત્પાદનો/SKU પોઈન્ટ ફાળવણી માટે પાત્ર છે?">
                            <p className="m-0">
                              <span>જવાબ:</span>તે ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સના દરેક ઉત્પાદન માટે લાગુ પડે છે. તમારે ફક્ત ઇન્વૉઇસ અપલોડ કરવા પડશે અને ઍપ્લિકેશનમાં ફિનોલેક્સ પ્રોડક્ટની ખરીદી મૂલ્યનો ઉલ્લેખ કરવો પડશે. ખરીદી મૂલ્ય પોઈન્ટ તમારા ખાતામાં પોઈન્ટ તરીકે ઉમેરવામાં આવે છે.
                              <br />
                              લાયક ઉત્પાદનો વિશે વધુ વિગતો માટે, તમે અમારા પ્રોગ્રામ હેલ્પડેસ્ક પર 18002024289 પર સોમવારથી શનિવાર સુધી સવારે 9.30 થી સાંજના 5.30 વાગ્યા સુધી સંપર્ક સાધી શકો છો.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.8 જો મારા ખાતામાંના રિવોર્ડ પોઈન્ટ્સ મારા દ્વારા કરાયેલી જાણ કરેલ ખરીદી કરતા ઓછા હોય તો શું?">
                            <p className="m-0">
                              <span>જવાબ:</span>અમારા પ્રોગ્રામ હેલ્પડેસ્ક સાથે 18002024289 પર સોમવારથી શનિવાર સુધી સવારે 9.30 થી સાંજના 5.30 વાગ્યા સુધી કનેક્ટ થાઓ. વૈકલ્પિક રીતે, તમે વધુ સ્પષ્ટતા મેળવવા માટે ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ સેલ્સ ટીમ સાથે પણ સંપર્કમાં રહી શકો છો
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.9 ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ સુપર પ્લમ્બર લૉયલ્ટી પ્રોગ્રામમાં દરેક રિવોર્ડ પોઇન્ટનું મૂલ્ય શું છે?">
                            <p className="m-0">
                              <span>જવાબ:</span>એક (01) રિવોર્ડ પોઈન્ટ્સ = 0.02 રૂપિયા
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.10 બોનસ પોઈન્ટ્સ :">
                            <p className="m-0">
                              <span>જવાબ:</span><br />
                              1. પ્રમાણીકરણ પછી, તમને જોડાવાના બોનસ તરીકે 10,000 પોઈન્ટ્સ પ્રાપ્ત થશે <br />
															2. પ્રથમ રિડેમ્પશન વિનંતી માટે બોનસ પોઈન્ટ્સનો ઉપયોગ કરી શકાતો નથી <br />
															3. તમે અનુગામી રિડેમ્પશન વિનંતી પર રિડેમ્પશન માટે બોનસ પોઈન્ટ્સનો ઉપયોગ કરી શકો છો <br />
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.11 શું હું મારા કમાયેલા પોઈન્ટ્સને બીજા રજિસ્ટર્ડ ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ સુપર પ્લમ્બર લૉયલ્ટી પ્રોગ્રામ એકાઉન્ટમાં ટ્રાન્સફર કરી શકું?">
                            <p className="m-0">
                              <span>જવાબ:</span>ના, તમે તમારા પોઈન્ટ્સ અન્ય કોઈ રજિસ્ટર્ડ સભ્યને ટ્રાન્સફર કરી શકતા નથી.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.12 હું મારા બેલેન્સ પોઈન્ટ કેવી રીતે તપાસું?">
                            <p className="m-0">
                              <span>જવાબ:</span>તમે મોબાઈલ ઍપ્લિકેશનમાં 'હેમબર્ગર' ટૅબ પર ક્લિક કરીને તમારા બેલેન્સ પોઈન્ટ્સ ચકાસી શકો છો.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.13 પોઈન્ટની માન્યતા શું છે?">
                            <p className="m-0">
                              <span>જવાબ:</span>તે તૈયાર થયાની તારીખથી 3(ત્રણ) વર્ષ.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.14 રિડેમ્પશન માટે જરૂરી ન્યૂનતમ પોઈન્ટ શું છે?">
                            <p className="m-0">
                              <span>જવાબ:</span>પ્રથમ રિડેમ્પશન 20000 પોઈન્ટ્સ પર કરવામાં આવે છે અને ત્યારબાદ વધુ રિડેમ્પશન કરવા માટે ઓછામાં ઓછા 5000 પોઈન્ટ્સ
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.15 હું મારા કમાયેલા પોઈન્ટ્સ કેવી રીતે રિડીમ કરી શકું?">
                            <p className="m-0">
                              <span>જવાબ:</span>તમારા સંચિત પોઈન્ટ રિડીમ કરવા માટે વિવિધ વિકલ્પો છે. નીચે ઉલ્લેખ કર્યા પ્રમાણે :<br />
                              1. આધાર સાથે લિંક કરેલા મોબાઈલ નંબર પર Paytm વૉલેટ ટ્રાન્સફર <br />
															2. ડાયરેક્ટ બેંક ટ્રાન્સફર <br />
															3. UPI ટ્રાન્સફર <br />
															4. ઇ-ગિફ્ટ વાઉચર્સ <br />
															5. ફિનોલેક્સ મર્ચેન્ડાઇઝ ચીજ-વસ્તુઓ <br />
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.16 હું મારા સંચિત પોઈન્ટ્સને કેટલી વાર રિડીમ કરી શકું?">
                            <p className="m-0">
                              <span>જવાબ:</span>તમે તમારા ખાતામાં ઓછામાં ઓછા 5000 પર્યાપ્ત બેલેન્સ પોઈન્ટ્સ ધરાવો છો, તો તમે પ્રોગ્રામના સમગ્ર સમયગાળા દરમ્યાન રિડેમ્પશનની વિનંતી કરી શકો છો.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.17 હું મારા રિડીમ કરેલ પુરસ્કારની સ્થિતિ કેવી રીતે જાણી શકું?">
                            <p className="m-0">
                              <span>જવાબ:</span>તમે રિડીમ પોઈન્ટ્સ ટ્રૅક રિવોર્ડ્સ હેઠળ ઍપમાં તમારું રિડેમ્પશન સ્ટેટસ ચેક કરી શકો છો. એકવાર તમારું EGV/UPI/Paytm વૉલેટ ટ્રાન્સફર/ ડાયરેક્ટ બેંક ટ્રાન્સફર સફળ થઈ જાય પછી તમને SMS અપડેટ પણ પ્રાપ્ત થશે.
                              <br />
                              જો કે, તમે તમારા રિડેમ્પશનની વર્તમાન સ્થિતિ જાણવા માટે 18002024289 સોમ-શનિ (સવારે 09.30 થી 05.30 વાગ્યા સુધી) પ્રોગ્રામ હેલ્પડેસ્કનો પણ સંપર્ક કરી શકો છો.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.18 મારા દ્વારા સબમિટ કરેલી ખરીદીઓ માટે મને પોઈન્ટ્સ મળ્યા છે કે કેમ તે હું કેવી રીતે જાણીશ?">
                            <p className="m-0">
                              <span>જવાબ:</span>ઇન્વૉઇસ ક્લિયર ઈમેજના સફળ અપલોડ પર, પોઈન્ટ્સ ઍપ્લિકેશનમાં અનઑડિટેડ પોઈન્ટ તરીકે દેખાશે અને તે એવા સંબંધિત અધિકૃત રિટેલરની મંજૂરી પછી જ રિડેમ્પશન માટે ઉપલબ્ધ થશે કે જેમની પાસેથી તમે ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ પ્રોડક્ટ્સ ખરીદી છે.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.19 મારું રિડીમ કરેલ ઉત્પાદન મેળવવામાં કેટલો સમય લાગશે?">
                            <p className="m-0">
														• UPI ટ્રાન્સફરને ડિલિવરી થવામાં 24 થી 48 કલાકનો સમય લાગશે <br />
														• ડાયરેક્ટ બેંક ટ્રાન્સફરને ડિલિવર થવામાં 24 થી 48 કલાકનો સમય લાગશે <br />
														• Paytm વૉલેટ ટ્રાન્સફરને ડિલિવર થવામાં 24 થી 48 કલાકનો સમય લાગશે <br />
														• ઇ-ગિફ્ટ વાઉચર્સ વાસ્તવિક સમયના આધારે આપવામાં આવશે અને મહત્તમ 24 કલાકની અંદર ઍપ્લિકેશનમાં પ્રતિબિંબિત થશે <br />
														• ફિનોલેક્સ મર્ચેન્ડાઈઝ સ્ટૉકની ઉપલબ્ધતા અને ડિલિવરી સ્થાનના આધારે 2-3 અઠવાડિયા લેશે  <br />
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.20 જો મારા જમા થયેલ પોઈન્ટ ખોટા હોય અથવા મને મારી ભાગીદારી માટે પોઈન્ટ ફાળવવામાં ન આવ્યા હોય, તો હું શું કરી શકું?">
                            <p className="m-0">
                              <span>જવાબ:</span>આવા સંજોગોમાં, તમે ઍપ્લિકેશનમાંથી તમારી ચિંતાની વિનંતી કરી શકો છો અથવા તમારા નોંધાયેલા મોબાઇલ નંબર સાથે અમારા હૅલ્પડૅસ્ક 18002024289 પર અમને કૉલ કરી શકો છો અને તમારી ચિંતાની વિનંતી કરી શકો છો. અમે ચિંતાના મુદ્દાની તપાસ કરીશું અને શક્ય તેટલી વહેલી તકે જવાબ આપીશું.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.21 હું ફિનોલેક્સ પાઇપ્સ અને ફિટિંગ્સ સુપર પ્લમ્બર પ્રોગ્રામ સેન્ટર કેવી રીતે પહોંચી શકું?">
                            <p className="m-0">
                              <span>જવાબ:</span>તમે અમારા પ્રોગ્રામ હૅલ્પલાઇન નંબર 18002024289 પર સવારે 9:30 થી સાંજના 5:30 સુધી અમારો સંપર્ક કરી શકો છો. સોમવાર થી શનિવાર.
                            </p>
                          </AccordionTab>
                          <AccordionTab header="પ્ર.22 શું તમે કૃપા કરીને ફિનોલેક્સ સુપર પ્લમ્બર લૉયલ્ટી પ્રોગ્રામના કેટલાક અન્ય ફાયદા જણાવશો?">
                            <p className="m-0">
                              <span>જવાબ:</span> હા, પ્લમ્બર્સ અને તેમના પરિવારો માટે બહુવિધ જોડતી પ્રવૃત્તિઓ હશે.
                            </p>
                          </AccordionTab>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
