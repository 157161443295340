import React, { useState, useEffect } from 'react'
import Header from '../../../common/Header'
import Footer from '../../../common/Footer'
import longarrow from '../../../../assets/img/longarrow.svg'
import shortarrow from '../../../../assets/img/shortarrow.svg'
import download from '../../../../assets/img/download.svg'
import pending from '../../../../assets/img/pending.svg'
import rejected from '../../../../assets/img/rejected.svg'
import registered from '../../../../assets/img/registered.svg'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import * as Constants from "../../../../Constants/index"
import Oval from '../../Loader/CircleLoade';
import swal from 'sweetalert'
import { Dropdown } from 'primereact/dropdown';

export default function DashBoardRetailer() {
    let token = localStorage.getItem('token');

    const navigate = useNavigate()
    // const [isVisible, setIsVisible] = useState(false);
    // const [topPlumber, setTopPlumber] = useState([]);
    const [dashboard, setDashboard] = useState({});
    // const [totalMember, setTotalMember] = useState('')
    const [loading, setLoading] = useState(false);
    // const [redemsummary, setRedemsummary] = useState(false)
    // const userType = localStorage.getItem('user_type')
    const [errors, setErrors] = useState({});
    const [mobile, setMobile] = useState('')
    const [selectedMonth, setSelectedMonth] = useState(null)
    const [month, setMonth] = useState(null)
    const [regDataCount, setRegDataCount] = useState(null)



    useEffect(() => {

        getMonthList()

        if (!token) {
            navigate('/');
        } else {
            try {
                setLoading(true);
                axios.get(Constants.baseUrl + `program/dashboard/retailer?size=160*160`, {
                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        platform: Constants.pt,
                        appVersion: Constants.av,
                        program: Constants.program
                    }
                })
                    .then((res) => {
                        if (res.status === 201 && res.data.output) {
                            setLoading(false);
                            setDashboard(res.data.output);
                        } else {
                            // swal(res.data.msg);
                        }
                    })

            } catch (error) {
                console.log(error)
            }
        }

    }, []);

    const getMonthList = () => {
        if (!token) {
            navigate('/');
        } else {
            try {
                setLoading(true);
                axios.get(Constants.baseUrl + `capture/invoice/retailer/month_year?gst=${localStorage.getItem('gst')}&mobile=${localStorage.getItem('user_mobile')}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        program: Constants.program
                    }
                })
                    .then((res) => {
                        if (res.status === 201 && res.data.result === 'success') {
                            getRegDataCount(res.data.output[0])
                            localStorage.setItem('month_name', res.data.output[0])
                            const months = res.data.output.map((item) => ({
                                name: item
                            }));
                            setSelectedMonth(months[0])
                            setMonth(months);
                        } else if (res.status === 201 && res.data.result === 'error') {
                            setLoading(false);
                        }
                    })

            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleRegCount = (e) => {
        setSelectedMonth(e.value)
        localStorage.setItem('month_name', e.value.name)
        getRegDataCount(e.value.name)
    }

    const getRegDataCount = (monthName) => {
        if (!token) {
            navigate('/');
        } else {
            try {
                setLoading(true);
                axios.get(Constants.baseUrl + `capture/invoice/month_year/invoice_count?monthYear=${monthName}&gst=${localStorage.getItem('gst')}&mobile=${localStorage.getItem('user_mobile')}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        program: Constants.program
                    }
                })
                    .then((res) => {
                        if (res.status === 201 && res.data.result === 'success') {
                            setLoading(false);
                            setRegDataCount(res.data.output)
                        } else if (res.status === 201 && res.data.result === 'error') {
                            setLoading(false);
                        }
                    })

            } catch (error) {
                console.log(error)
            }
        }
    }

    const validateForm = () => {
        let errors = {};

        if (!mobile) {
            errors.mobile = 'Mobile no. is required';
        } else if (!/^[6-9]\d{9}$/.test(mobile)) {
            errors.mobile = 'Invalid mobile no.';
        } else if (mobile.length !== 10) {
            errors.mobile = 'Mobile no. must be 10 digits';
        }

        return errors;
    };

    const sendRegLink = (number) => {
        let token = localStorage.getItem('token');
        setErrors({});

        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        } try {
            setLoading(true);
            axios.post(Constants.baseUrl + `user/sendAppDownloadLink?mobile=${number}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    platform: Constants.pt,
                    appVersion: Constants.av,
                    program: Constants.program,
                    company: Constants.company
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            swal({
                                text: res.data.msg,
                                icon: 'success',
                                timer: 2000,
                                buttons: false,
                            })
                            setLoading(false)
                            setMobile('')
                        } else if (res.data.code === 12001) {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                            setLoading(false)

                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })

        } catch (error) {
            console.log(error)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            sendRegLink(mobile);
        }
    };
    return (
        <>
            {loading ? <Oval /> : null}

            <Header />
            <section className="blog-area pt182">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog">
                                <div className="row">
                                    <div className="col-md-12 col-xs-12">
                                        <div className="cardboard">
                                            <div className="row mauto">
                                                <div className="col-md-3 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance border-right border-bottom">
                                                        <div className="content">
                                                            <div className="text">
                                                                Total number of Plumber
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.members}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 padding">
                                                    <div className="info-box bg-pink hover-expand-effect  lbalance border-bottom border-right">
                                                        <div className="content">
                                                            <div className="text">
                                                                Total number of Active Plumbers
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.memberActive}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 padding">
                                                    <div className="info-box bg-pink hover-expand-effect border-bottom border-right lbalance">
                                                        <div className="content">
                                                            <div className="text">
                                                                Total number of Inctive Plumbers
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.memberInActive}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 padding">
                                                    <div className="info-box bg-pink hover-expand-effect border-bottom lbalance">
                                                        <div className="content">
                                                            <div className="text">
                                                                Total number of Dormant 1 Plumbers
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.userDormant1}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 padding">
                                                    <div className="info-box bg-pink hover-expand-effect border-right lbalance">
                                                        <div className="content">
                                                            <div className="text">
                                                                Total number of Dormant 2 Plumbers
                                                            </div>
                                                            <div className="number count-to">
                                                                {dashboard.userDormant2}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-30">
                                    <div className="col-md-8 col-sm-8 col-xs-12 col-23">
                                        <div className="row">
                                            <div className="col-md-3 col-xs-12">
                                                <div className="card flex justify-content-center">
                                                    <Dropdown value={selectedMonth} onChange={(e) => handleRegCount(e)} options={month} optionLabel="name"
                                                        placeholder="Select a Month" className="w-full md:w-14rem" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-30">
                                    <div className="col-md-8 col-sm-8 col-xs-12 col-23">
                                        <div className="row">
                                            <div className="col-md-4 col-xs-12">
                                                <div className="kpibox">
                                                    <div className="upperkpi">
                                                        <div className="iconkpi">
                                                            <img src={registered} alt='' />
                                                        </div>
                                                        <div className="namekpi">
                                                            APPROVED
                                                        </div>
                                                    </div>
                                                    <div className="kpilink">
                                                        {regDataCount === null ? 0 : regDataCount?.invoiceApproved}
                                                    </div>
                                                </div>
                                                <div className="viewdetail"><Link to="/approved_retailer"> View Details <img src={shortarrow} alt='' /></Link></div>
                                            </div>
                                            <div className="col-md-4 col-xs-12">
                                                <div className="kpibox">
                                                    <div className="upperkpi">
                                                        <div className="iconkpi">
                                                            <img src={pending} alt='' />
                                                        </div>
                                                        <div className="namekpi">
                                                            PENDING FOR APPROVAL
                                                        </div>
                                                    </div>
                                                    <div className="kpilink">
                                                        {regDataCount === null ? 0 : regDataCount?.invoicePending}
                                                    </div>
                                                </div>
                                                <div className="viewdetail"><Link to="/pending_for_approval_retailer"> View Details <img src={shortarrow} alt='' /></Link></div>
                                            </div>
                                            <div className="col-md-4 col-xs-12">
                                                <div className="kpibox">
                                                    <div className="upperkpi">
                                                        <div className="iconkpi">
                                                            <img src={rejected} alt='' />
                                                        </div>
                                                        <div className="namekpi">
                                                            REJECTED
                                                        </div>
                                                    </div>
                                                    <div className="kpilink">
                                                        {regDataCount === null ? 0 : regDataCount?.invoiceRejected}
                                                    </div>
                                                </div>
                                                <div className="viewdetail"><Link to="/rejected_retailer"> View Details <img src={shortarrow} alt='' /></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12 col-23">
                                        <div className="card downloadapp">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <div className="forreg">Enter the plumber number for Sending the app download link:</div>
                                                    <Link to="#" className="download"> <img src={download} alt='' /> </Link>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <input type="text" className="frmcntrl" placeholder="Mobile No." maxLength="10" value={mobile} onChange={(event) => {
                                                                const input = event.target.value;
                                                                if (/^\d*$/.test(input)) {
                                                                    setMobile(input);
                                                                }
                                                            }} onKeyDown={handleKeyDown} />
                                                        </div>
                                                        {errors.mobile && (
                                                            <div className="error-message">{errors.mobile}</div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <Link to="#" className="btn login_white_btn" disabled={mobile.trim() === ''}
                                                                onClick={() => sendRegLink(mobile)}> Send <img src={longarrow} alt='' /> </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
