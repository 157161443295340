import React, { useState } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import loginBanner from '../../../assets/img/loginbanner.jpg'
import axios from 'axios';
import * as Constants from "../../../Constants/index"
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom'
import './loginStyle.css'
import whitearrow from '../../../assets/img/whitearrow.svg'
import { useFormik } from 'formik'
import Oval from '../Loader/CircleLoade';


export default function ChangePassword() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    const changePassFormik = useFormik({
        initialValues: {
            oldPass: '',
            newPass: '',
            confirmPass: '',
        },
        validate: (data) => {
            let errors = {}
            if (!data.oldPass) {
                errors.oldPass = "Please enter old password.";
            }
            if (!data.newPass) {
                errors.newPass = "Please enter new password.";
            } else {
                // Password pattern: at least 8 characters with uppercase, lowercase, a number, and a special character
                const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

                if (!passwordPattern.test(data.newPass)) {
                    errors.newPass = "Password is not valid. Please ensure it contains at least 8 characters with uppercase, lowercase, a number, and a special character.";
                }
            }

            if (!data.confirmPass) {
                errors.confirmPass = "Please enter confirm password.";
            } else if (data.newPass !== data.confirmPass) {
                errors.confirmPass = "Passwords do not match.";
            }


            return errors
        },
        onSubmit: (data) => {
            handleChangePass(data)
            console.log(data)

        },
    })
    const isChangePasswordValid = (name) =>
        !!(changePassFormik.touched[name] && changePassFormik.errors[name]);
    const getChangePassFormErrorMessage = (name) => {
        return (
            isChangePasswordValid(name) && (
                <small className="error-message-changePass">{changePassFormik.errors[name]}</small>
            )
        );
    };

    const handleChangePass = async (data) => {
        let token = localStorage.getItem('token');
        if (!token) {
            navigate('/')
        } else {
            try {
                setLoading(true);
                await axios.post(Constants.baseUrl + 'user/manage/change-password',
                    {
                        oldPassword: data.oldPass,
                        newPassword: data.newPass,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`, 'Accept-Language': localStorage.getItem('language'),
                            appVersion: Constants.av,
                            platform: Constants.pt,
                            // company: Constants.company,
                            program: Constants.program,
                        },
                    }
                )
                    .then((res) => {
                        if (res.status === 200) {
                            if (res.data.code === 10001) {
                                setLoading(false);
                                changePassFormik.resetForm();
                                swal({
                                    text: res.data.msg,
                                    icon: 'success',
                                    buttons: false,
                                    timer: 2000
                                });
                            } else {
                                setLoading(false);
                                swal({
                                    text: res.data.msg,
                                    icon: 'error',
                                    buttons: false,
                                    timer: 2000,
                                });
                            }
                        } else {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            });
                            setLoading(false)
                        }
                    });

            } catch (error) {
                console.log(error);
            }
        }
    }

    const areAllValuesBlank = () => {
        const { oldPass, newPass, confirmPass } = changePassFormik.values;
        return !oldPass && !newPass && !confirmPass
    };
    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="loginsec mt134 bglogin" style={{ backgroundImage: `url(${loginBanner})`, }}>
                <div className="container">
                    <div className="loginwindow">
                        <div className="row mr0">
                            <div className="col-md-8 col-md-offset-2 col-xs-12 loginside">
                                <div className="formlogin">
                                    <h2>Change Password</h2>
                                    <form onSubmit={changePassFormik.handleSubmit}>
                                        <div className="form-group form-group-mb">
                                            <label htmlFor="user id">Old Password</label>
                                            <input type="text"
                                                id='oldPass'
                                                value={changePassFormik.values.oldPass}
                                                onChange={changePassFormik.handleChange}
                                                placeholder="Old password"
                                                className="form-control frm"
                                            />
                                            {getChangePassFormErrorMessage('oldPass')}
                                        </div>
                                        <div className="form-group form-group-mb">
                                            <label htmlFor="user id">New Password</label>
                                            <input type="text"
                                                id='newPass'
                                                value={changePassFormik.values.newPass}
                                                onChange={changePassFormik.handleChange}
                                                placeholder="New password"
                                                className="form-control frm"
                                            />
                                            {getChangePassFormErrorMessage('newPass')}
                                        </div>
                                        <div className="form-group form-group-mb">
                                            <label htmlFor="user id">Confirm New Password</label>
                                            <input type="text"
                                                id='confirmPass'
                                                value={changePassFormik.values.confirmPass}
                                                onChange={changePassFormik.handleChange}
                                                placeholder="New password"
                                                className="form-control frm"
                                            />
                                            {getChangePassFormErrorMessage('confirmPass')}
                                        </div>

                                        <div className="form-group mtop20">
                                            <button type="submit" className="btn loginbuttn" disabled={areAllValuesBlank()}>Change Password <img className="logarrow" src={whitearrow} alt="" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
